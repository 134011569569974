import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { CdkDragEnd } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-schedule-swiper',
  templateUrl: './schedule-swiper.component.html',
  styleUrls: ['./schedule-swiper.component.scss']
})
export class ScheduleSwiperComponent implements OnInit, AfterViewInit {
  @ViewChild('swiperContainer', { static: true }) scheduleSwiperContainer: ElementRef;
  @ViewChild('swiperContent', { static: true }) scheduleSwiperContent: ElementRef;
  dragPosition = { x: 0, y: 0 };
  containerWidth: number;
  contentWidth: number;
  styleExp = {
    transition : ''
  };
  constructor() { }

  ngOnInit() {
  }


  ngAfterViewInit() {
    this.containerWidth = this.scheduleSwiperContainer.nativeElement.clientWidth;
    this.contentWidth = this.scheduleSwiperContent.nativeElement.clientWidth;
  }

  dragStart() {
    this.styleExp.transition = '';
  }

  dragEnd(event: CdkDragEnd) {
    this.styleExp.transition = '0.5s';
    if (this.containerWidth > this.contentWidth) {
      this.dragPosition = { x: 0, y: this.dragPosition.y };
    } else {
      if (event.source.getFreeDragPosition().x > 0) {
        this.dragPosition = { x: 0, y: this.dragPosition.y };
      } else {
        if (event.source.getFreeDragPosition().x + this.contentWidth < this.containerWidth) {
          this.dragPosition = { x: this.containerWidth - this.contentWidth, y: this.dragPosition.y };
        }
      }
    }
  }


}
