import { Component, OnDestroy, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { HomeService } from '../home.service';
import { HomeDayCalender } from 'src/app/shared/models/home/homedayCalendar';
import { FrontActions } from 'src/app/shared/enums/actions.enum';
import { IPayloadShift } from 'src/app/shared/interfaces/payloadShift.interface';
import { UserService } from 'src/app/shared/services/user.service';
import { ScheduleSettings } from 'src/app/shared/models/settings/settings';
import { Subscription } from 'rxjs';


enum swiperName {
  CARDS = 'cards',
  CALENDER = 'calender'
}

@Component({
  selector: 'app-planning-day',
  templateUrl: './planning-day.component.html',
  styleUrls: ['./planning-day.component.scss']
})
export class PlanningDayComponent implements OnInit, OnDestroy {
  public calenderData: HomeDayCalender[];
  public checkedItem = 1;
  public checkedShift = 0;
  public swiperCalenderIndex = 1;

  public swiperCardsIndex = 1;
  scheduleSettings: ScheduleSettings;
  sub: Subscription;

  public cardsConfig: SwiperConfigInterface = {
    slidesPerView: 'auto',
    navigation: true,
    width: 293,
    spaceBetween: 16
  };

  constructor(private homeService: HomeService, private userService: UserService) {}

  ngOnDestroy(): void {
    //  this.sub.unsubscribe();
  }

  ngOnInit() {
    this.getCalenderData();
    this.sub = this.userService.typologySetting.subscribe(settings => {
      this.scheduleSettings = settings.scheduleSettings;
    });
  }

  getCalenderData() {
    this.homeService.getCalenderData().subscribe(calenderData => {
      this.calenderData = calenderData;
      this.InitActiveCardIndex(this.calenderData[this.checkedItem]);
    });
  }

  check(itemIndex: number, shiftIndex: number = 0) {
    if (this.checkedItem !== itemIndex) {
      this.setIndex(swiperName.CALENDER, itemIndex);
    }
    this.setIndex(swiperName.CARDS, shiftIndex);
  }

  setIndex(swiper: string, index: number) {
    if (swiper === swiperName.CARDS) {
      this.checkedShift = index;
    } else if (swiper === swiperName.CALENDER) {
      this.swiperCalenderIndex = index;
      this.checkedItem = index;
    }
  }

  handleActions(event: { action: FrontActions; payload: IPayloadShift }) {
    switch (event.action) {
      case FrontActions.CHECK_SHIFT:
        this.check(event.payload.indexItem, event.payload.indexShift);
        break;
      case FrontActions.PRISE_EN_SERVICE:
        break;
      default:
        break;
    }
  }

  /**
   * Active shift card must be initialized depending on current Date/Time (Now).
   *
   * If (Now < first daily shift) => 1st card is active.
   *
   * If (Now > last daily shift) => last card is active
   *
   * If (Now is between any card departure/arrival) => this card is active
   *
   * If (Now is between a card arrival and next card departure) => the later card is active
   *
   * @param dailyCalendar Daily calendar shifts
   */
  InitActiveCardIndex(dailyCalendar: HomeDayCalender) {
    const now = new Date();
    if (!dailyCalendar || !dailyCalendar.parts.length || !dailyCalendar.parts[0].details) {
      return;
    }
    const lastShiftIndex = dailyCalendar.parts.length - 1;
    const dailyDeparture = new Date(dailyCalendar.parts[0].details.departure.date);
    const dailyArrival = new Date(dailyCalendar.parts[lastShiftIndex].details.arrival.date);

    if (now < dailyDeparture) {
      this.checkedShift = 0;
      return;
    }
    if (now > dailyArrival) {
      this.checkedShift = lastShiftIndex;
      return;
    }

    this.checkedShift = dailyCalendar.parts.findIndex((part, index) => {
      const departure = new Date(part.details.departure.date);
      const arrival = new Date(part.details.arrival.date);
      const previousArrival = index ? new Date(dailyCalendar.parts[index - 1].details.arrival.date) : null;
      return (now > departure && now < arrival) || (previousArrival && now > previousArrival && now < departure);
    });
  }
}
