import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { PartialAbsenceRequestService } from '../partial-absence-request.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AbsenceService } from '../../absence.service';

@Component({
  selector: 'app-partial-absence-recap',
  templateUrl: './partial-absence-recap.component.html',
  styleUrls: ['./partial-absence-recap.component.scss']
})
export class PartialAbsenceRecapComponent implements OnInit {
  absenceData: any;


  constructor(
    private router: Router,
    public snackbarService: SnackbarService,
    public partialAbsenceRequestService: PartialAbsenceRequestService,
    public absenceService: AbsenceService
  ) {}

  ngOnInit() {
    this.absenceData = this.partialAbsenceRequestService.absenceData;
  }

  sendDataToHistory() {
    this.absenceService.saveAbsence(this.absenceData, 'PARTIAL').subscribe(
      () => {
        this.endRequest();
        this.snackbarService.show('ABSENCE_REQUEST.SUCCESS', 'success');
      },
      () => {
        this.snackbarService.show('ABSENCE_REQUEST.ERROR', 'error');
      }
    );
  }

  goToPreviousStep() {
    this.partialAbsenceRequestService.step = 2;
  }

  endRequest() {
    this.router.navigate(['/absence/history']);
    this.partialAbsenceRequestService.step = 1;
  }
}
