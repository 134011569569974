import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { TranslateModule } from '@ngx-translate/core';
import { PlanningDayComponent } from './planning-day/planning-day.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { HomeService } from './home.service';
import { PlanningStatusComponent } from './planning-status/planning-status.component';
import { TaskComponent } from './task/task.component';
import { DueDateModalComponent } from './dueDateModal/dueDateModal.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CheckInConditionsComponent } from './checkInConditions/checkInConditions.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CheckOutConditionsComponent } from './checkOutConditions/checkOutConditions.component';
import {Nl2BrPipeModule} from 'nl2br-pipe';
import { PunchInOutConditionsComponent } from './punchInOutConditions/punchInOutConditions.component';
import {NewsModule} from '../news/news.module';
import {SurveysModule} from '../surveys/surveys.module';


const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule.forRoot(),
        SwiperModule,
        NgSelectModule,
        SharedModule,
        Nl2BrPipeModule,
        NewsModule,
        SurveysModule
    ],
    declarations: [
        HomeComponent,
        PlanningDayComponent,
        PlanningStatusComponent,
        TaskComponent,
        DueDateModalComponent,
        CheckInConditionsComponent,
        CheckOutConditionsComponent,
        PunchInOutConditionsComponent
    ],
    exports: [
        HomeComponent,
    ],
    providers: [
        {
            provide: SWIPER_CONFIG,
            useValue: DEFAULT_SWIPER_CONFIG
        },
        HomeService,
        NgbActiveModal
    ]
})
export class HomeModule { }
