import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '../user.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TypologyDeviceSettings } from '../../models/settings/settings';

@Injectable()
export class DocumentsAccessService implements CanActivate {

  constructor(
    private userService: UserService,
    private router: Router,
  ) { }

  canActivate(): Observable<boolean> {
    return this.userService.typologyDeviceSettings.pipe(
      map(
        (typologyDeviceSettings: TypologyDeviceSettings) => {
          if (!typologyDeviceSettings.typologyDeviceSettingsData.myDocuments) {
            this.router.navigate(['/home']);
            return false;
          }
          return true;
        }
      )
    );
  }

}
