import {Injectable} from '@angular/core';
import {NewsApiService} from '@shared/services/api/news.api.service';
import {NewsListItemModel} from '@shared/models/news/newsListItem.model';
import {NewsDetailsModel} from '@shared/models/news/newsDetails.model';

@Injectable()
export class NewsService {
  /**
   * All fetched items
   */
  list: Array<NewsListItemModel> = [];
  /**
   * Indicates whether the service is about
   * to fetch items from the server
   */
  isLoadingList = false;
  /**
   * Next page index of the list
   * Used to set the page search query for the web service
   */
  nextPageIndex = 0;
  /**
   * Indicates whether the service is about
   * to fetch items from the server
   */
  isLoadingDetails = false;
  /**
   * Current active model
   */
  currentDetailsItem: NewsDetailsModel;


  constructor(
    private newsApiService: NewsApiService
  ) {

  }

  /**
   * Rest list function, used to reset page index and items list
   * FUTURE_USAGE
   */
  resetList(searchParams: {} = {}) {
    this.list = [];
    this.nextPageIndex = 0;
  }

  /**
   * Function to fetch more items
   */
  fetchMore(searchParams: {} = {}) {
    this._fetchList();
  }

  /**
   * Fetch more items in the list
   * This function is used internally only
   */
  private _fetchList() {
    // empty it for now
    this.resetList();
    this.isLoadingList = true;
    this.newsApiService.list({
      page: this.nextPageIndex,
    }).subscribe((items) => {
      this.nextPageIndex++;
      if (items && items.length) {
        items.map(e => {
          this.list.push(new NewsListItemModel(e));
        });
      }
      this.isLoadingList = false;
    }, () => {
      this.isLoadingList = false;
    });
  }

  /**
   * Load item details.
   * Get an article details
   * We return a promise because the component should be
   * notified when the details is ready
   */
  fetchItem(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.isLoadingDetails = true;
      this.newsApiService.read(id).subscribe((details) => {
        this.currentDetailsItem = new NewsDetailsModel(details);
        this.isLoadingDetails = false;
        resolve(true);
      }, () => {
        this.isLoadingDetails = false;
        resolve(true);
      });
    });
  }
}
