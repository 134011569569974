import { SwapRequestSettings } from './../../../../shared/models/settings/swapRequestSettings/swapRequestSettings';
import { UserService } from 'src/app/shared/services/user.service';
import { RestStepsService } from './../../rest-steps.service';
import { Component, OnInit} from '@angular/core';
import { Planning } from 'src/app/shared/models/planning/planning';
import { SwapService } from '../../swap.service';
import * as moment from 'moment';
import { swapPlanningEnum } from '../../enums/planning.enum';
import { LoaderModalService } from 'src/app/shared/services/loader-modal.service';
import { Subscription } from 'rxjs';
import { ScheduleSettings } from 'src/app/shared/models/settings/settings';

@Component({
  selector: 'app-rest-first-calendar',
  templateUrl: './rest-first-calendar.component.html',
  styleUrls: ['./rest-first-calendar.component.scss']
})
export class RestFirstCalendarComponent implements OnInit {

  i18nPrefix = 'SWAP_REQUEST.CALENDAR.';
  planning: Planning[];
  daySelected: Planning;
  swapRequestSettings: SwapRequestSettings;
  sub: Subscription;
  scheduleSettings: ScheduleSettings;

  constructor(
    private swapService: SwapService,
    private stepsService: RestStepsService,
    private userService: UserService,
    private loaderModalService: LoaderModalService
  ) { }

  ngOnInit() {
    this.userService.typologySetting.subscribe(typoSettings => {
      this.swapRequestSettings = typoSettings.swapRequestSettings;
    });
    this.stepsService.continueEnable(false);
    this.continueEnable();
    this.getPlanning();
    this.getScheduleSettings();
  }

  getScheduleSettings(){
    this.sub = this.userService.typologySetting.subscribe(
      settings => {
        this.scheduleSettings = settings.scheduleSettings;
      }
    );
  }

  selectDay(day: Planning) {
    if (this.daySelected === day) {
      this.daySelected = null;
      this.stepsService.selectServiceDate(null);
      this.stepsService.setShiftId(null);
      this.stepsService.continueEnable(false);
      this.continueEnable();
    } else {
      this.daySelected = day;
      this.stepsService.selectServiceDate(moment(day.date));
      this.stepsService.setShiftId(day.events[0].meta.shiftId);
      this.stepsService.continueEnable(true);
      this.stepsService.setUserShift(day.events[0].meta);
    }
    this.stepsService.resetSwapSearch();
  }

  continueEnable() {
    if (
      this.swapRequestSettings.rest.dateRequired ||
      this.stepsService.selectedServiceDate
    ) {
      this.stepsService.continueEnable(true);
    }
  }

  getPlanning() {
    const start = moment();
    const end = moment().add(2, 'month').endOf('month');
    this.loaderModalService.show();
    this.swapService.getPlanning(swapPlanningEnum.SERVICE, start, end).subscribe(planning => {
      this.loaderModalService.dismiss();
      this.planning = planning;
      this.getSelectedDay();
    }, () => {
      this.loaderModalService.dismiss();
    });
  }

  getSelectedDay() {
    const selectedDay = this.stepsService.selectedServiceDate;
    if (selectedDay) {
      const IndexMonth = this.planning.findIndex(planning => {
        return planning.month.getMonth() === selectedDay.month();
      });
      const Indexday = this.planning[IndexMonth].events.findIndex(day => {
        return day.start.getDate() === selectedDay.date();
      });
      this.daySelected = {
        date : this.planning[IndexMonth].events[Indexday].start,
        month: this.planning[IndexMonth].month,
        events: []
      };
      this.stepsService.continueEnable(true);
    }
  }
}
