import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Tabs } from '../../interfaces/tabs.interface';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent {

  @Input() tabs: Tabs[];

  constructor(public router: Router) { }

}
