import { ShiftType } from './../../../shared/enums/home/shiftType.enum';
import { Component, OnInit } from '@angular/core';
import { VolunteerService } from '../volunteer.service';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AbsenceService } from '../../absence/absence.service';
import { PlanningDetails } from 'src/app/shared/models/planning/planningDetails';
import { VolunteeringStatusEnum } from 'src/app/shared/enums/volunteer/volunteeringStatus.enum';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { PeriodType } from 'src/app/shared/enums/home/periodType.enum';
import { DayType } from 'src/app/shared/enums/home/dayType.enum';
import { LoaderModalService } from 'src/app/shared/services/loader-modal.service';

@Component({
  selector: 'app-volunteer-request',
  templateUrl: './volunteer-request.component.html',
  styleUrls: ['./volunteer-request.component.scss']
})
export class VolunteerRequestComponent implements OnInit {

  volunteerPeriodTypeEnum = PeriodType;
  volunteerWorkTypeEnum = ShiftType;
  volunteershiftTypeEnum = DayType;

  plannings: PlanningDetails[] = [];
  public slotConfig: SwiperConfigInterface = {
    slidesPerView: 'auto',
    navigation: true,
    width: 350,
    spaceBetween: 16,
    centeredSlides: true,
  };
  public swiperSlotIndex = 0;
  hasOnlyPendingDay = false;

  constructor(
    public volunteerDataService: VolunteerService,
    private absenceService: AbsenceService,
    private snackBarService: SnackbarService,
    private loaderModalService: LoaderModalService ) { }

  ngOnInit() {
    this.loaderModalService.show();
    this.volunteerDataService.volunteerData.forEach(day =>
      this.getDateDetails(day.date));

    this.hasOnlyPendingDay =
      this.volunteerDataService.volunteerData.filter(day => day.status !== VolunteeringStatusEnum.WAITING).length > 0;
  }

  getDateDetails(date: string) {
    this.absenceService.getPlanningDetails(date).subscribe(planningDetails => {
      this._addPlanning(planningDetails, date);
      this.loaderModalService.dismiss();
    }, () => {
      const planningDetails = new PlanningDetails(PeriodType.EMPTY, null, null, [], -1, null, null,  null);
      this._addPlanning(planningDetails, date);
      this.loaderModalService.dismiss();
    });

  }

  private _addPlanning(planningDetails: PlanningDetails, date: string) {
    planningDetails.date = date;
    this.plannings.push(planningDetails);
    this.plannings = this.plannings.sort((a, b) => (new Date(a.date) > new Date(b.date)) ? 1 : -1);
  }

  goToPrevStep() {
    this.volunteerDataService.step = 1;
  }

  validate() {
    this.volunteerDataService.validate().subscribe(() => {
      this.volunteerDataService.step = 1;
      this.snackBarService.show('VOLUNTEER.REQUEST.SUCCESS', 'success');
    }, () => {
      this.snackBarService.show('VOLUNTEER.REQUEST.ERROR', 'error');
    });
  }

  cancel() {
    this.volunteerDataService.delete().subscribe(() => {
      this.volunteerDataService.step = 1;
      this.snackBarService.show('VOLUNTEER.REQUEST.SUCCESS', 'success');
    }, () => {
      this.snackBarService.show('VOLUNTEER.REQUEST.ERROR', 'error');
    });
  }

}
