import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';

@Injectable()
export class ForcedPasswordChangeCanActivateService implements CanActivate {

  constructor(
    private userService: UserService,
    private router: Router,
  ) { }

  canActivate(): boolean {
    if (!this.userService.forceSetPassword) {
      this.router.navigate(['/home']);
    }
    return this.userService.forceSetPassword;
  }
}
