import { SnackbarService } from './../../../shared/components/snackbar/snackbar.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwapService } from './../swap.service';
import { SwapRequestHistory } from '../models/swapHistory';
import { Component, OnInit } from '@angular/core';
import { SwapStatusEnum, StateEnum } from '../enums/swapHistoryState.enum';
import { SwapTypeEnum } from '../enums/requestType.enum';
import { NotificationModalComponent } from 'src/app/shared/components/notification-modal/notification-modal.component';
import { UserService } from 'src/app/shared/services/user.service';
import { LoaderModalService } from 'src/app/shared/services/loader-modal.service';

@Component({
  selector: 'app-history',
  templateUrl: './swap-history.component.html',
  styleUrls: ['./swap-history.component.scss']
})
export class SwapHistoryComponent implements OnInit {

  swapRequestHistory: SwapRequestHistory[];
  stateEnum = StateEnum;
  swapStatusEnum = SwapStatusEnum;
  swapTypeEnum = SwapTypeEnum;

  constructor(
    private swapService: SwapService,
    private snackbarService: SnackbarService,
    private modalService: NgbModal,
    public userService: UserService,
    private loLoaderModalServicead: LoaderModalService
    ) {}

  ngOnInit() {
    this.getSwapHistory();
  }

  getSwapHistory() {
    this.loLoaderModalServicead.show();
    this.swapService.getSwapRequests().subscribe(histories => {
      this.swapRequestHistory = histories.map(history => new SwapRequestHistory({...history}));
      this.loLoaderModalServicead.dismiss();
      if (!histories || !histories.length) {
        const modalRef =  this.modalService.open(NotificationModalComponent, {
          centered: true,
        });
        modalRef.componentInstance.icon = 'icon-alert-red';
        modalRef.componentInstance.title = 'SWAP_REQUEST.HISTORY.NO_RESULT_FOUND';
        modalRef.componentInstance.message = 'SWAP_REQUEST.HISTORY.NO_RESULT_FOUND';
      }
    },
    () => {
      this.loLoaderModalServicead.dismiss();
      this.snackbarService.show('ABSENCE_REQUEST.HISTORY.ERROR', 'error');
    });
  }
}
