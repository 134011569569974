import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AUTH_URL } from 'src/environments/environment';
import { UserService } from '../services/user.service';
import { AuthenticationService } from 'src/app/auth.service';
import { UserGuardResolver } from '../services/user-guard.service';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

  constructor(
    private userService: UserService,
    private authService: AuthenticationService,
    private userGuard: UserGuardResolver,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let defaultDevice = null;
    try {
      defaultDevice = JSON.parse(localStorage.getItem('default_device'));
    } catch (error) { }

    let newHeaders = req.headers;
    const badgeToken = localStorage.getItem('badgeToken');

    if (this.userService.token) {
      newHeaders = newHeaders.append('Authorization', this.userService.token);
    } else if (badgeToken) {
      newHeaders = newHeaders.append('Authorization-Badge', badgeToken);
    }
    if (localStorage.getItem('admin')) {
      newHeaders = newHeaders.append('admin', localStorage.getItem('admin'));
    }
    newHeaders = newHeaders.append('deviceId', defaultDevice && defaultDevice.id ? `${defaultDevice.id}` : '0');
    newHeaders = newHeaders.append('deviceType', defaultDevice && defaultDevice.type ? defaultDevice.type : 'DESKTOP');
    // name is deviceCode
    newHeaders = newHeaders.append('deviceCode', defaultDevice && defaultDevice.name ? defaultDevice.name : 'deviceCode');
    const authReq = req.clone({headers: newHeaders});
    return next.handle(authReq).pipe(
      takeUntil(this.userGuard.cancelPendingRequests$),
      tap(
        (res: any) => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            // badge mode
            if (err.status === 403 && badgeToken) {
              return this.authService.logout();
            }
            // auth mode
            if (err.status === 403 && !badgeToken) {
              sessionStorage.setItem('lastRoute', location.pathname + location.search);
              return window.location.href = AUTH_URL.AUTHORIZE;
            }
            if (err.status === 401 && !badgeToken) {
              return this.authService.forbidden();
            }
          }
        }
      )
    );
  }
}
