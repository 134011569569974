import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { API_URLS } from 'src/environments/api-urls';
import {map} from 'rxjs/operators';
import {MessageGroup, MessageGroupResponse} from '../../shared/models/message/messageGroup';
import {UserService} from '../../shared/services/user.service';
import {Message, MessageResponse} from '../../shared/models/message/message';
import {MessageDateGroup} from '../../shared/enums/message/messageDateGroup.enum';
import { MessageAction } from 'src/app/shared/models/message/message.action';
import { MessageActionType } from 'src/app/shared/enums/message/messageAction.enum';


@Injectable()
export class MessagesService {

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
    moment.locale('fr');
  }

  search(keywords: string) {
    return this.http.get<MessageResponse>(`${API_URLS.MESSAGE_SEARCH}?keywords=${keywords}`).pipe(
      map(messageResponse => {
        return this.getMessages(messageResponse);
      })
    );
  }

  private getMessages(messageResponse: MessageResponse) {
    const messageGroups: MessageGroup[] = [];
    const msgs = messageResponse.messages.map(message => {
      return new Message(message.id, message.archived, message.title, message.content, moment(message.creationDate),
        message.favorite, message.markedRead, message.sender, message.type, message.status, message.concernDate, message.url);
    });
    const today = moment().startOf('day');
    const yesterday = moment(today).subtract(1, 'day').startOf('day');
    const firstDayOfWeek = moment(today).isoWeekday(this.userService.site.firstDayOfWeek).startOf('day');
    const todayMessages = msgs.filter(message => {
      return message.creationDate.format('YYYY-MM-DD') === today.format('YYYY-MM-DD');
    });
    const yesterdayMessages = msgs.filter(message => {
      return message.creationDate.format('YYYY-MM-DD') === yesterday.format('YYYY-MM-DD');
    });
    const weekMessages = msgs.filter(message => {
      return message.creationDate < yesterday && message.creationDate >= firstDayOfWeek;
    });
    const otherMessages = msgs.filter(message => {
      return message.creationDate < yesterday && message.creationDate < firstDayOfWeek;
    });
    messageGroups.push(new MessageGroup(MessageDateGroup.TODAY, 'MESSAGE.GROUPMESSAGE.TODAY', todayMessages));
    messageGroups.push(new MessageGroup(MessageDateGroup.YESTERDAY, 'MESSAGE.GROUPMESSAGE.YESTERDAY', yesterdayMessages));
    messageGroups.push(new MessageGroup(MessageDateGroup.THIS_WEEK, 'MESSAGE.GROUPMESSAGE.THIS_WEEK', weekMessages));
    messageGroups.push(new MessageGroup(MessageDateGroup.BEFORE, 'MESSAGE.GROUPMESSAGE.BEFORE', otherMessages));

    return new MessageGroupResponse(messageGroups, messageResponse.hasNext);
  }


  getMessagesGroup(page = 0): Observable<MessageGroupResponse> {
    return this.http.get<MessageResponse>(`${API_URLS.MESSAGE}?page=${page}`).pipe(
      map(messageResponse => {
        return this.getMessages(messageResponse);
      })
    );
  }

  addMessageToFavorites(message: Message): Observable<any> {
    return this.http.put(`${API_URLS.MESSAGE}/${message.id}/action`, new MessageAction(MessageActionType.FAVORITE));
  }

  markMessageAsRead(message: Message) {
    return this.http.put(`${API_URLS.MESSAGE}/${message.id}/action`, new MessageAction(MessageActionType.READ));
  }

  archiveMessage(message: Message) {
    return this.http.put(`${API_URLS.MESSAGE}/${message.id}/action`, new MessageAction(MessageActionType.ARCHIVE));
  }


}
