import { DayType } from './../../../../shared/enums/home/dayType.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SnackbarService } from './../../../../shared/components/snackbar/snackbar.service';
import { RestStepsService } from './../../rest-steps.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { SwapService } from '../../swap.service';
import { SwapSearchShift } from 'src/app/features/swap/models/swapSearchShift';
import { NotificationModalComponent } from 'src/app/shared/components/notification-modal/notification-modal.component';
import { LoaderModalService } from 'src/app/shared/services/loader-modal.service';

@Component({
  selector: 'app-swap-rest-search',
  templateUrl: './swap-search.component.html',
  styleUrls: ['./swap-search.component.scss']
})
export class SwapRestSearchComponent implements OnInit {

  searchForm: UntypedFormGroup;
  i18nPrefix = 'SWAP_REQUEST.SEARCH.';
  dayType = DayType;

  constructor(
    public stepService: RestStepsService,
    public swapService: SwapService,
    private formBuilder: UntypedFormBuilder,
    private snackbarService: SnackbarService,
    private modalService: NgbModal,
    private loaderModalService: LoaderModalService
  ) { }

  ngOnInit() {
    this.createSearchForm();
    this.continueEnable();
  }

  createSearchForm() {
    this.searchForm = this.formBuilder.group({
      searchTerm: [null]
    });
    this.searchForm.controls.searchTerm.setValue(
      this.stepService.swapRestSearch && this.stepService.swapRestSearch.searchTerm ? this.stepService.swapRestSearch.searchTerm : null
    );
  }

  continueEnable() {
    this.stepService.swapRestSearch && this.stepService.swapRestSearch.colleagueShift ?
    this.stepService.continueEnable(true) : this.stepService.continueEnable(false);
  }

  onSubmit() {
    this.stepService.swapRestSearch.searchTerm = this.searchForm.controls.searchTerm.value;
    this.getSearchShiftResults();
  }

  getSearchShiftResults() {
      this.loaderModalService.show();
      this.stepService.getSearchShift().subscribe(shifts => {
      this.stepService.swapRestSearch.swapSearchShift = shifts.map((shift, index) => (new SwapSearchShift({id: index, ...shift})));
      // display notification while get empty result
      if (!shifts || !shifts.length) {
        const modalRef =  this.modalService.open(NotificationModalComponent, {
          centered: true,
        });
        modalRef.componentInstance.icon = 'icon-alert-red';
        modalRef.componentInstance.title = 'SWAP_REQUEST.SEARCH.NO_RESULT_TITLE';
        modalRef.componentInstance.message = 'SWAP_REQUEST.SEARCH.TRY_AGAIN';
      }
      this.loaderModalService.dismiss();

    }, () => {
      // diplay error message while get error from back
      this.loaderModalService.dismiss();
      this.snackbarService.show('COMMON.NOTIFICATION.GENERAL_ERROR', 'error');
    });
  }

  selectShift(index: number) {
    const savedShift = this.stepService.swapRestSearch.colleagueShift;
    const colleagueShift = this.stepService.swapRestSearch.swapSearchShift[index];
    if (savedShift && savedShift.id === colleagueShift.id) {
      this.stepService.swapRestSearch.colleagueShift = null;
      this.stepService.continueEnable(false);
    } else {
      this.stepService.swapRestSearch.colleagueShift = colleagueShift;
      this.stepService.continueEnable(true);
    }
  }
}
