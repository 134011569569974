import { SettingsActivation } from './../../../shared/enums/setting/typology-settings/settingsActivation.enum';
import { SwapRequestSettings } from './../../../shared/models/settings/swapRequestSettings/swapRequestSettings';
import { SwapService } from './../swap.service';
import { UserService } from './../../../shared/services/user.service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ContractualPeriod } from 'src/app/features/swap/models/contractualPeriod';

@Component({
  selector: 'app-swap-rules',
  templateUrl: './swap-rules.component.html',
  styleUrls: ['./swap-rules.component.scss']
})
export class SwapRulesComponent implements OnInit {

  swapRequestSettings: SwapRequestSettings;
  counterLowerBound: string;
  counterUpperBound: string;
  tteLowerBound: string;
  tteUpperBound: string;
  contractualPeriod: ContractualPeriod = {
    code: 'A',
    label: 'année',
  };
  settingsActivation = SettingsActivation;

  constructor(
    public activeModal: NgbActiveModal,
    private userService: UserService,
    private swapService: SwapService
  ) { }

  ngOnInit() {
    this.swapService.getContractualPeriod().subscribe((period: ContractualPeriod) => {
      this.contractualPeriod = period;
    });
    this.userService.typologySetting.subscribe(typo => {
      this.swapRequestSettings = typo.swapRequestSettings;
    });
    this.transformBounds();
  }

  transformBounds() {
    if (this.swapRequestSettings.counter) {
      this.counterLowerBound = moment.utc(
        moment.duration(this.swapRequestSettings.counter.lowerBound, 'seconds')
          .as('milliseconds')
      ).format('HH:mm').replace(':', 'h').replace('00h00', '0h');
      this.counterUpperBound = moment.utc(
        moment.duration(this.swapRequestSettings.counter.upperBound, 'seconds')
          .as('milliseconds')
      ).format('HH:mm').replace(':', 'h');
    }
    this.tteLowerBound = moment.utc(
      moment.duration(this.swapRequestSettings.tteLowerBound, 'seconds')
        .as('milliseconds')
    ).format('HH:mm').replace(':', 'h').replace('00h00', '0h');
    this.tteUpperBound = moment.utc(
      moment.duration(this.swapRequestSettings.tteUpperBound, 'seconds')
        .as('milliseconds')
    ).format('HH:mm').replace(':', 'h');
  }
}
