import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { API_URLS } from 'src/environments/api-urls';
import { Link } from '../../models/sidebar/link';

@Injectable()
export class SideBarService {
  public links = new Subject<Link[]>();

  constructor(private http: HttpClient) {}

  getLinks(): Observable<Link[]> {
    return this.http.get<Link[]>(API_URLS.LINK);
  }

  addlinks() {
    this.getLinks().subscribe(res => {
      this.links.next(res);
    });
  }
}
