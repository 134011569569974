import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DocumentListService } from './document-list.service';
import { FoldersListComponent } from './foldersList/list.component';
@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  isDisplay = true;

  @ViewChild(FoldersListComponent, { static: true }) private listDocument: FoldersListComponent;

  constructor(
    public service: DocumentListService,
    private router: Router
  ) { }

  ngOnInit() {
    if (this.service.lastPath === this.service.pathFirstLevel) {
      this.service.getDocuments(this.service.pathFirstLevel, true);
    }
    this.beforeNavigate();
  }

  beforeNavigate() {
    this.subscription = this.router.events.subscribe((navigationStart) => {
      if (navigationStart instanceof NavigationStart) {
        if (!navigationStart.url.startsWith('/documents')) {
          this.service.resetDocuments();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  changeDisplay() {
    this.isDisplay = !this.isDisplay;
  }

  onBackClick(): void {
    this.service.getBack(this.service.lastPath);
    this.listDocument.loadDocuments();
  }

  onClearSearch(): void {
    this.service.clearSearch();
    this.listDocument.loadDocuments();
  }

  onSelectFolder(event): void {
    this.service.getContentOfFolder(event);
    this.listDocument.loadDocuments();
  }

  onSearch() {
    this.service.search();
    this.listDocument.loadDocuments();
  }
}
