import * as moment from 'moment';
import { SwapShiftDeparture } from './swapShiftDeparture';
import { SwapShiftArrival } from './swapShiftArrival';
export class SwapSearchShift {
  id: number;
  adminNumber: string;
  firstName: string;
  lastName: string;
  shift: SwapShift;

  get fullName(): string {
    return this.firstName + ' ' + this.lastName;
  }

  get formatedWorkingTime(): string {
    const hours = moment.utc(this.shift.workingTime * 60 * 1000).format('HH');
    const minutes = moment.utc(this.shift.workingTime * 60 * 1000).format('mm');
    return hours + 'h' + minutes;
  }

  constructor(args: {
    id: number;
    adminNumber: string;
    firstName: string;
    lastName: string;
    shift: SwapShift;
  }) {
    this.id = args.id;
    this.adminNumber = args.adminNumber;
    this.firstName = args.firstName;
    this.lastName = args.lastName;
    this.shift = args.shift;
  }
}

export class SwapShift {
    id: number;
    date: Date;
    type: string;
    departure: SwapShiftDeparture;
    arrival: SwapShiftArrival;
    workingTime: number;
    label: string;

    constructor(
      id: number,
      date: Date,
      type: string,
      departure: SwapShiftDeparture,
      arrival: SwapShiftArrival,
      workingTime: number,
      label: string
    ) {
      this.id = id;
      this.date = date;
      this.type = type;
      this.departure = departure;
      this.arrival = arrival;
      this.workingTime = workingTime;
      this.label = label;
    }
}

