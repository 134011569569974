import { ShiftDetails } from './shiftDetails';
import { ShiftType } from '../../enums/home/shiftType.enum';
import { Mission } from '../planning/mission';

export class Shift {
  id: any;
  reference: string;
  type: ShiftType;
  label: string;
  partLabel: string;
  passed: boolean;
  isModified?: string;
  details?: ShiftDetails;
  missions?: Array<Mission>;
  displayMissions: boolean;
  hasComments: boolean;
  constructor(
    id: any,
    reference: string,
    type: ShiftType,
    label: string,
    partLabel: string,
    passed: boolean,
    isModified?: string,
    details?: ShiftDetails,
    missions?: Array<Mission>,
    hasComments?: boolean
  ) {
    this.id = id;
    this.reference = reference;
    this.type = type;
    this.label = label;
    this.partLabel = partLabel;
    this.passed = passed;
    this.isModified = isModified;
    this.details = details;
    this.missions = missions;
    this.displayMissions = false;
    this.hasComments = hasComments;
  }
}
