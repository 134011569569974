import {BaseModel} from '@shared/models/BaseModel';
import QuestionTypeEnum from '@shared/enums/surveys/questionType.enum';
import {QuestionOption} from '@shared/interfaces/question';


export class SurveyQuestionModel extends BaseModel {
  /**
   * Id
   *
   */
  id: number;
  /**
   * order of question in the list
   *
   */
  rank: number;
  /**
   * description
   *
   */
  description: string;
  /**
   * Label
   *
   */
  label: string;
  /**
   * List of choices
   *
   */
  options: Array<QuestionOption>;
  /**
   * Type of question
   */
  type: QuestionTypeEnum;
  /**
   *
   */
  mandatory: boolean;

  constructor(args: {
    id: number,
    type: string,
    label: string,
    rank: number,
    options: Array<string>,
    description: string,
    boolean: boolean

  }) {
    super(args);
  }

  /**
   * Minimal serialisation
   */
  toJSON(fields = []) {
    return {
      id: this.id,
      options: JSON.parse(JSON.stringify(this.options))
    };
  }
}
