import {Component, OnInit} from '@angular/core';
import {Task} from 'src/app/shared/models/home/task';
import {HomeService} from './home.service';
import {DueDateModalComponent} from './dueDateModal/dueDateModal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {storageKeys} from 'src/app/shared/constants/constants';
import {UserService} from '../../shared/services/user.service';
import {MessagesCountService} from 'src/app/shared/services/messages-count.service';
import {SettingsActivation} from 'src/app/shared/enums/setting/typology-settings/settingsActivation.enum';
import {Router} from '@angular/router';
import { SurveysWsService } from 'src/app/shared/services/surveys.ws.service';
import { LoaderModalService } from 'src/app/shared/services/loader-modal.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  tasks: Task[] = [];
  swapsEnabled = false;
  messagesEnabled = false;
  waitingSurveysCount = 0;
  newsActiveSetting = false;

  constructor(
    private homeService: HomeService,
    private modalService: NgbModal,
    private userService: UserService,
    private loaderService: LoaderModalService,
    public messagesCountService: MessagesCountService,
    public surveysApiService: SurveysWsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getTasks();
    this.getTypologiesPermissions();
    this.getWaitingSurveysCount();
    this.getNewsSettings();
  }

  getNewsSettings() {
    this.userService.typologyDeviceSettings.subscribe(
      settings => {
        this.newsActiveSetting = settings.typologyDeviceSettingsData.news;
      }
    );
  }
  getTasks() {
    this.loaderService.show();
    this.homeService.getTasks().subscribe(tasks => {
      this.tasks = tasks;
      this.tasks.forEach(task => {
        const expiryDate = localStorage.getItem(`${storageKeys.DUE_DATE_MODAL_EXPIRY}_${task.title}_${this.userService.adminNumber}`);
        let isExpired = true;
        if (expiryDate && new Date(Number(expiryDate)) > new Date()) {
          isExpired = false;
        }
        if (task.remainingDays <= task.limitDays && task.remainingDays >= 0 && isExpired) {
          this.openDueDateTask(task);
        }
      });
      this.loaderService.dismiss();
    }, () => {
      this.loaderService.dismiss();
    });
  }

  getWaitingSurveysCount() {
    this.surveysApiService.waiting().subscribe(data => {
      this.waitingSurveysCount = data.waiting;
    });
    this.messagesCountService.getUnreadMessageCount();
  }

  openDueDateTask(task: Task) {
    const modalRef = this.modalService.open(DueDateModalComponent, {
      centered: true
    });
    modalRef.componentInstance.task = task;
  }

  getTypologiesPermissions() {
    this.userService.typologySetting.subscribe(typoSetting => {
      this.swapsEnabled = typoSetting.swapRequestSettings.status === SettingsActivation.ACTIVE;
      this.messagesEnabled = typoSetting.messageSettings.status === SettingsActivation.ACTIVE;
    });
  }

  navigateToSwaps() {
    if (this.swapsEnabled) {
      this.router.navigate(['/swap/history']);
    }
  }

  navigateToMessages() {
    if (this.messagesEnabled) {
      this.router.navigate(['/messages']);
    }
  }

  navigateToSurveys() {
    this.router.navigate(['/surveys']);
  }
}
