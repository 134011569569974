import { VolunteerPlanning } from '../../shared/enums/volunteer/volunteering';
import { UserService } from 'src/app/shared/services/user.service';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { Planning } from 'src/app/shared/models/planning/planning';
import { API_URLS } from 'src/environments/api-urls';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IVolunteer } from './volunteer.interface';
@Injectable()
export class VolunteerService {

step = 1;

volunteerData: IVolunteer[] = [];

constructor(private userService: UserService, private http: HttpClient) {
    moment.locale('fr');
}

getPlanning(startDate: moment.Moment, endDate: moment.Moment): Observable<Planning[]> {
  let params = new HttpParams();
  params = params.append('from', startDate.format('YYYY-MM-01'));
  params = params.append('to', endDate.format('YYYY-MM-DD'));
  params = params.append('withVehicleCode', 'false');
  params = params.append('deviceId', `${this.userService.defaultDevice.id}`);
  params = params.append('deviceCode', `${this.userService.defaultDevice.deviceCode}`);
  params = params.append('deviceType', `${this.userService.defaultDevice.type}`);
  return this.http.get<VolunteerPlanning[]>(`${API_URLS.VOLUNTEERING}/planning`, {params})
    .pipe(
      map(days => {
        const arrayDates = this.getMonthBetweenDates(startDate, endDate);
        const planning: Planning[] = [];
        arrayDates.forEach(monthlyDate => {
          const planningInstance = new Planning();
          const filtredDays = days.filter(day => moment(new Date(day.date as any)).get('M') + 1 === monthlyDate.month);
          if (filtredDays && filtredDays.length) {
            planningInstance.month = new Date(filtredDays[0].date as any);
            planningInstance.events = filtredDays.map(fd => {
              return {
                title: '',
                start: new Date(fd.date as any),
                meta: {
                  shifts: fd.numberOfPoints > 0 ? Array((fd.numberOfPoints)).fill(0) : [],
                  date: fd.date,
                  code: fd.code,
                  type: fd.type,
                  numberOfPoints: fd.numberOfPoints,
                  period: fd.period,
                  valid: fd.valid,
                  recomposedShift: fd.recomposedShift,
                  reference: fd.reference,
                  volunteeringStatus: fd.volunteeringStatus
                }
              };
            });
          } else {
            planningInstance.month = new Date(monthlyDate.year, monthlyDate.month - 1, 1);
            planningInstance.events = [];
          }
          planning.push(planningInstance);
        });
        return planning;
      }
      )
    );
}

  getMonthBetweenDates(startDate: moment.Moment, endDate: moment.Moment): Array<any> {
    const result = [];
    while (startDate.isBefore(endDate)) {
      result.push({
        month: startDate.get('M') + 1,
        year: startDate.get('year')
      });
      startDate.add(1, 'month');
    }
    return result;
  }

  validate() {
    return this.http.post(`${API_URLS.VOLUNTEERING}/create`, {dates: this.volunteerData.map(day => day.date)});
  }

  delete() {
    return this.http.post(`${API_URLS.VOLUNTEERING}/delete`, {dates: this.volunteerData.map(day => day.date)});
  }

}
