<div class="app-carousel ps-3 pe-3" *ngIf="calenderData && calenderData.length">
    <div class="app-carousel-calender">
        <app-schedule-swiper>
            <ng-container *ngFor="let item of calenderData; index as indexItem;">
                <div class="calender-container d-flex flex-column align-items-center text-center text-white me-4">
                    <div class="weekday ubuntu-bold text-uppercase" [ngClass]="{'disabled': !item.valid && !item.workingTime}">
                        <span>{{ item.date | dateFormat : 'ddd'}}</span>
                    </div>
                    <div class="day ubuntu-bold pointer" [ngClass]="{'activeDay' : indexItem === checkedItem }"
                        (click)="check(indexItem)">
                        <span [ngClass]="{'disabled': !item.valid && !item.workingTime , 'recomposed-shift': item.recomposedShift}">{{ item.date | dateFormat : 'D' }}</span>
                    </div>
                 
                    <div class="shifts">
                        <div class="d-flex justify-content-center">
                        <app-planning-status 
                         [numberOfPoints]="item.numberOfPoints"
                         [code]="item.code"
                         [displayVacationCount]="scheduleSettings?.displayVacationCount"
                         [displayServiceName]="scheduleSettings?.displayServiceName"
                         [serviceName]="item?.reference"
                         [period]="item?.period"
                         [type]="item?.type"
                         [indexItem]="indexItem"
                         [checkedItem]="checkedItem"
                         [checkedShift]="checkedShift"
                         [workingTime]="item.workingTime"
                         (actions)="handleActions($event)">
                        </app-planning-status>
                        </div>
                    </div>
                </div>
            </ng-container>
        </app-schedule-swiper>
    </div>
    <div class="app-carousel-cards">
        <swiper class="swiper-container" [config]="cardsConfig">
            <ng-container *ngFor="let shift of calenderData[checkedItem].parts; index as indexShift;">    
                <app-calender-card 
                    [ngClass]="{'pointer': indexShift !== checkedShift}" 
                    [checked]="checkedShift === indexShift" 
                    [date]="calenderData[this.checkedItem].date | dateFormat :'YYYY-MM-DD'"
                    [shiftData]="shift" 
                    [shiftPartIndex]="indexShift" 
                    [serviceReference]="calenderData[checkedItem].reference"
                    [period]="calenderData[checkedItem].period" 
                    (click)="check(checkedItem,indexShift)" 
                    (actions)="handleActions($event)"
                >
                </app-calender-card>
            </ng-container>
        </swiper>
    </div>
</div>
