import { Component, OnInit, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { PlanningService } from './planning.service';
import { Planning } from 'src/app/shared/models/planning/planning';

import * as moment from 'moment';
import { UserService } from 'src/app/shared/services/user.service';
import { ScheduleSettings } from 'src/app/shared/models/settings/settings';
import { PeriodType } from 'src/app/shared/enums/home/periodType.enum';
import { PeriodSettings } from 'src/app/shared/models/settings/periodSettings/periodSetting';
import { CalendarType } from 'src/app/shared/enums/planning/calendar-type.enum';
import { combineLatest } from 'rxjs';
@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss']
})
export class PlanningComponent implements OnInit, AfterViewChecked {
  @ViewChild('planningContainer') planningContainer: ElementRef;
  isScrolledUp = false;
  previousMonths = 3;
  scheduleSettings: ScheduleSettings;
  periodSettings: PeriodSettings;
  isCalendar = true;
  calendarType: CalendarType;
  weeks = [];
  selectedWeek: any;
  public date = new Date();
  plannigsWeeks;
  periodType = PeriodType;
  perdiodDuration: number;
  private focused = false;

  constructor(
    private planningService: PlanningService,
    private userService: UserService,
  ) {}


  ngOnInit() {
    combineLatest([
      this.planningService.currentCalendarType,
      this.planningService.currentWeekDates,
      this.userService.typologySetting
    ])
    .subscribe(([calendar, date, settings]) => {
        this.focused = false;
        this.calendarType = calendar;
        this.selectedWeek = date;
        this.scheduleSettings = settings.scheduleSettings;
        if (!this.calendarType) {
          this.calendarType = settings.scheduleSettings.defaultPlanningDisplayMode;
        }
        this.periodSettings = settings.periodSettings;
        this.getWeeksCalendare();
      });
  }

  getWeeksCalendare() {
    this.weeks = [];
    const firstDayOfCalendar = moment().subtract(this.previousMonths, 'months').startOf('month').format('YYYY-MM-DD');
    this.perdiodDuration = this.periodSettings?.detailDuration
                           + this.periodSettings?.positionDuration
                           + this.periodSettings?.previewDuration - 1;
    const lastDayofCalendar = moment(new Date()).add(this.perdiodDuration, 'day').format('YYYY-MM-DD');
    let firstDayOfWeek = moment(firstDayOfCalendar).isoWeekday(this.planningService.firstDayOfWeek).startOf('day').format('YYYY-MM-DD');
    if (moment(firstDayOfCalendar).isSameOrBefore(firstDayOfWeek)) {
      firstDayOfWeek = moment(firstDayOfCalendar).isoWeekday(this.planningService.firstDayOfWeek).startOf('day').add(-1, 'week').format('YYYY-MM-DD');
    }

    let i = 0;
    while (moment(firstDayOfWeek).isSameOrBefore(lastDayofCalendar)) {
      this.weeks.push({
        id: i,
        first: firstDayOfWeek,
        last: moment(firstDayOfWeek).add(13, 'day').format('YYYY-MM-DD')
      });
      if (moment(lastDayofCalendar).isBetween(firstDayOfWeek, moment(firstDayOfWeek).add(13, 'day'))) {
        break;
      }
      firstDayOfWeek = moment(firstDayOfWeek).add(1, 'week').format('YYYY-MM-DD');
      i++;
    }
    if (this.selectedWeek === null) {
        this.selectedWeek = this.weeks.find(element => moment(new Date().setHours(0, 0, 0, 0)).add(7, 'days')
                            .isBetween(element.first, element.last));
    }
  }

  setCalendarType(type){
    this.calendarType = type;
    this.planningService.updateCalendarType(this.calendarType);
  }

  increaseDate() {
    if (this.selectedWeek.id < this.weeks.length - 1) {
      this.selectedWeek = this.weeks[this.selectedWeek.id + 1];
      this.planningService.updateAWeekDates(this.selectedWeek);
    }
  }

  decreaseDate() {
    if (this.selectedWeek.id > 0) {
      this.selectedWeek = this.weeks[this.selectedWeek.id - 1];
      this.planningService.updateAWeekDates(this.selectedWeek);
    }
  }

  ngAfterViewChecked() {
    this.autoScrollToCurrentPlanning();
  }

  autoScrollToCurrentPlanning() {
    if (!this.focused &&
      this.planningContainer && this.planningContainer.nativeElement.children[0].children[0].children[this.previousMonths]) {
      const top = this.planningContainer.nativeElement.children[0]?.children[0]?.children[this.previousMonths]?.offsetTop;
      const containerTop = this.planningContainer.nativeElement.offsetTop;
      this.planningContainer.nativeElement.scrollTop = top - containerTop - 10;
      this.focused = true;
    }
  }

  getSelectedOption(event) {
    this.selectedWeek = event;
    this.planningService.updateAWeekDates(event);
  }
}
