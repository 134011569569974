import { SwapSearchShift } from 'src/app/features/swap/models/swapSearchShift';

export class SwapSearch {
    startTimeHour: number;
    startTimeMinute: number;
    endTimeHour: number;
    endTimeMinute: number;
    searchTerm: string;
    swapSearchShift: SwapSearchShift[];
    colleagueShift: SwapSearchShift;
}

export class SwapRestSearch {
    searchTerm: string;
    swapSearchShift: SwapSearchShift[];
    colleagueShift: SwapSearchShift;
}
