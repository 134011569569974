import { UserService } from './../../../shared/services/user.service';
import { Permission } from '../models/swap-request';
import { SwapService } from './../swap.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { SettingsActivation } from 'src/app/shared/enums/setting/typology-settings/settingsActivation.enum';

@Component({
  selector: 'app-new-swap-request',
  templateUrl: './new-swap-request.component.html',
  styleUrls: ['./new-swap-request.component.scss']
})
export class NewSwapRequestComponent implements OnInit {

  permission: Permission = new Permission(false);
  swapRestEnabled = false;
  swapServiceEnabled = false;

  constructor(
    private router: Router,
    private swapService: SwapService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.swapService.getSwapPermission().subscribe(permission => {
      this.permission = permission;
    });
    this.userService.typologySetting.subscribe(typoSetting => {
      this.swapRestEnabled = typoSetting.swapRequestSettings.rest.status === SettingsActivation.ACTIVE;
      this.swapServiceEnabled = typoSetting.swapRequestSettings.service.status === SettingsActivation.ACTIVE;
    });

  }

  navigateToService() {
    if (this.swapServiceEnabled) {
      this.router.navigate(['/swap-request/service']);
    }
  }

  navigateToRest() {
    if (this.swapRestEnabled) {
      this.router.navigate(['/swap-request/rest']);
    }
  }
}
