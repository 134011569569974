import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentsComponent } from './documents.component';
import { FoldersListComponent } from './foldersList/list.component';
import { TranslateModule } from '@ngx-translate/core';
import { DocumentsService } from './documents.service';
import { FormsModule } from '@angular/forms';
import { LinkyModule } from 'ngx-linky';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { RecentFilesListComponent } from './recentList/list.component';
import { DetailsComponent } from './details/details.component';
import { DocumentListService } from './document-list.service';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forRoot(),
    FormsModule,
    LinkyModule,
    Nl2BrPipeModule,
    SharedModule
  ],
  declarations: [
    DocumentsComponent,
    DetailsComponent,
    FoldersListComponent,
    RecentFilesListComponent
  ],
  providers: [
    DocumentsService,
    DocumentListService
  ],
})
export class DocumentsModule { }
