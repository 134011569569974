import { PeriodType } from '../../enums/home/periodType.enum';
import { Shift } from '../home/shift';
import { LocationAndTime } from './mission';
import { DayType } from '../../enums/home/dayType.enum';

export class PlanningDetails {
  period: PeriodType;
  shifts: Shift[];
  departure?: LocationAndTime;
  arrival?: LocationAndTime;
  numberOfPoints?: number;
  label?: string;
  date?: string;
  type?: DayType;
  reference?: string;

  constructor(
    period: PeriodType,
    departure: LocationAndTime,
    arrival: LocationAndTime,
    shifts: Shift[],
    numberOfPoints: number,
    label: string,
    type: DayType,
    reference: string,
  ) {
    this.period = period;
    this.departure = departure;
    this.arrival = arrival;
    this.shifts = shifts;
    this.numberOfPoints = numberOfPoints;
    this.label = label;
    this.type = type;
    this.reference = reference;
  }
}
