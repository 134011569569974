export class CounterHistory {
  code: string;
  label: string;
  format: string;
  mouvements: Mouvement[];
  isForecast: boolean;
}

export class Mouvement {
  date: Date;
  quantity: number;

  get decimal(): string {
    const hours = this.quantity / 60 ;
    const decimal = hours.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    return decimal;
  }

  constructor(args: {
    date: Date;
    quantity: number;
  }) {
    this.date = args.date;
    this.quantity = args.quantity;
  }
}
