import { Message } from './message';
import { MessageDateGroup } from '../../enums/message/messageDateGroup.enum';

export class MessageGroup {
    group: MessageDateGroup;
    label: string;
    messages: Array<Message>;

    constructor(group: MessageDateGroup, label: string, messages: Array<Message>) {
        this.group = group;
        this.label = label;
        this.messages = messages;
    }
}

export class  MessageGroupResponse {
        messageGroups: Array<MessageGroup>;
        hasNext: boolean;

        constructor(messageGroups: Array<MessageGroup>, hasNext: boolean) {
            this.messageGroups = messageGroups;
            this.hasNext = hasNext;
        }
}
