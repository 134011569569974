import { VolunteerRequestComponent } from './volunteer-request/volunteer-request.component';
import { VolunteerSelectComponent } from './volunteer-select/volunteer-select.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VolunteerComponent } from './volunteer.component';
import { VolunteerCalendarComponent } from './volunteer-calendar/volunteer-calendar.component';
import { RouterModule } from '@angular/router';
import { CalendarModule } from 'angular-calendar';
import { VolunteerService } from './volunteer.service';
import { SwiperModule } from 'ngx-swiper-wrapper';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    TranslateModule.forRoot(),
    RouterModule,
    CalendarModule,
    SwiperModule
  ],
  declarations: [
    VolunteerComponent,
    VolunteerCalendarComponent,
    VolunteerSelectComponent,
    VolunteerRequestComponent
  ],
  providers: [VolunteerService]
})
export class VolunteerModule {}
