import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrepayService } from '../prepay.service';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { LoaderModalService } from 'src/app/shared/services/loader-modal.service';
import { UserService } from 'src/app/shared/services/user.service';
import { PrepaySettings } from 'src/app/shared/models/settings/prepaySettings/PrepaySettings';

@Component({
    selector: 'app-prepay-details',
    templateUrl: './prepay-details.component.html',
    styleUrls: ['./prepay-details.component.scss']
})
export class PrepayDetailsComponent implements OnInit {

    prepayCounters = [];
    headerStartDate: string;
    headerEndDate: string;
    balances = [];
    movementModel = [];
    prepaySettings: PrepaySettings;

    constructor(
        private route: ActivatedRoute,
        private prepayService: PrepayService,
        private userService: UserService,
        private router: Router,
        private snackBarService: SnackbarService,
        private loaderModalService: LoaderModalService
    ) { }

    ngOnInit() {
        this.getPrepaySettings();
        this.headerStartDate = this.route.snapshot.paramMap.get('start');
        this.headerEndDate = this.route.snapshot.paramMap.get('end');
        this.loaderModalService.show();
        this.getPrepayBalances(this.headerStartDate, this.headerEndDate);
        this.getPrepayCounters(this.headerStartDate, this.headerEndDate);
        this.getMouvementModel(this.headerStartDate, this.headerEndDate);
    }

    getPrepaySettings(){
        this.userService.typologySetting.subscribe(typoSetting => {
          this.prepaySettings = typoSetting.prepaySettings;
        });
    }

    getMouvementModel(start: string, end: string){
        this.prepayService.getMouvementModel(start, end).subscribe((res) => {
            this.movementModel = res.map( movement => movement );
        }, () => {
            this.snackBarService.show('PREPAY.COUNTERS.ERROR', 'error');
            this.loaderModalService.dismiss();
        });
    }

    getPrepayBalances(start: string, end: string){
        this.prepayService.getPrepayBalances(start, end).subscribe((res) => {
            this.balances = res;
        }, () => {
            this.snackBarService.show('PREPAY.COUNTERS.ERROR', 'error');
            this.loaderModalService.dismiss();
        });
    }

    getPrepayCounters(start: string, end: string){
        this.prepayService.getPrepayCounters(start, end).subscribe((res) => {
            this.prepayCounters = res;
            this.loaderModalService.dismiss();
        }, () => {
            this.loaderModalService.dismiss();
            this.snackBarService.show('PREPAY.COUNTERS.ERROR', 'error');
        });
    }

    getBack() {
        this.router.navigate(['/prepay']);
    }

}
