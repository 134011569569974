import { Component, OnInit } from '@angular/core';
import { FullDayAbsenceRequestService } from '../fullDay-absence-request.service';
import { AbsenceService } from '../../absence.service';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationModalComponent } from 'src/app/shared/components/notification-modal/notification-modal.component';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { UserService } from 'src/app/shared/services/user.service';
import { absenceCountersCodes } from 'src/app/shared/constants/constants';
import * as moment from 'moment';
import { LoaderModalService } from 'src/app/shared/services/loader-modal.service';

@Component({
  selector: 'app-full-day-absence-counter-request',
  templateUrl: './fullDay-absence-counter-request.component.html',
  styleUrls: ['./fullDay-absence-counter-request.component.scss']
})
export class FullDayAbsenceCounterRequestComponent implements OnInit {

  remainingSlots: any = [];
  counters: any;
  counterRequestForm: UntypedFormGroup;
  public swiperSlotIndex = 0;
  isAutomaticLeave = false;
  isAutomaticAbsence = false;

  public slotConfig: SwiperConfigInterface = {
    slidesPerView: 'auto',
    navigation: true,
    width: 250,
    spaceBetween: 16,
    centeredSlides: true,
  };

  constructor(
    public fullDayAbsenceRequestService: FullDayAbsenceRequestService,
    public absenceService: AbsenceService,
    private formBuilder: UntypedFormBuilder,
    public modalService: NgbModal,
    private userService: UserService,
    private loaderModalService: LoaderModalService
  ) { }


  ngOnInit() {
    this.createCountersRequestForm();
    this.userService.typologySetting.subscribe(typoSetting => {
      this.isAutomaticLeave = typoSetting.absenceRequestSettings.fullDayAbsence.absencePeriodCounterEnabled;
      this.isAutomaticAbsence = typoSetting.absenceRequestSettings.fullDayAbsence.leavePeriodCounterEnabled;
    });
    this.getRemainingSlots(this.fullDayAbsenceRequestService.absence.startDate, this.fullDayAbsenceRequestService.absence.endDate);
    this.initFormWithData();
  }

  getRemainingSlots(startDate: any, endDate: any) {
    this.absenceService.getFullDayRemainingSlots(
      moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), this.fullDayAbsenceRequestService.absence.groupingId)
      .subscribe(slots => {
      this.remainingSlots = slots;
    });
  }

  getCounterBalance() {
    this.loaderModalService.show();
    this.absenceService.getCountersBalance(
      this.fullDayAbsenceRequestService.absence.startDate.format('YYYY-MM-DD'), 'FULL_DAY',
      this.fullDayAbsenceRequestService.absence.groupingId)
      .subscribe(counters => {
        this.counters = counters;
        this.loaderModalService.dismiss();
      }, () => {
        this.loaderModalService.dismiss();
      });
  }

  createCountersRequestForm() {
    this.getCounterBalance();
    this.counterRequestForm = this.formBuilder.group({
      counter: [null, [Validators.required]],
      comment: [null]
    });
  }

  setAbsenceData() {
    this.fullDayAbsenceRequestService.absence.counterCode = this.counterRequestForm.controls.counter.value;
    if (this.counterRequestForm.controls.counter.value !== absenceCountersCodes.AUTOMATIC_ABSENCE &&
    this.counterRequestForm.controls.counter.value !== absenceCountersCodes.AUTOMATIC_LEAVE) {
      this.fullDayAbsenceRequestService.absence.counterLabel =
      this.counters.find((counter: any) => counter.code === this.fullDayAbsenceRequestService.absence.counterCode).label;
    }
    this.fullDayAbsenceRequestService.absence.comment = this.counterRequestForm.controls.comment.value;
  }

  initFormWithData() {
    this.counterRequestForm.controls.counter.setValue(this.fullDayAbsenceRequestService.absence.counterCode);
    this.counterRequestForm.controls.comment.setValue(this.fullDayAbsenceRequestService.absence.comment);
    this.fullDayAbsenceRequestService.absence.counters = null;
  }

  goToPrevStep() {
    this.fullDayAbsenceRequestService.goToPrevStep();
  }

  goToNextStep() {
    if (this.counterRequestForm.valid) {
      this.setAbsenceData();
      this.absenceService.verifyAbsence(this.fullDayAbsenceRequestService.absence, 'FULL_DAY').subscribe(dataVerification => {
        if (dataVerification.deadlineOverlap) {
          this.openErrorModal('ABSENCE_REQUEST.MODAL.DEADLINE_OVERLAP_MESSAGE');
        } else if (!dataVerification.hasAccess) {
          this.openErrorModal();
        } else {
          this.fullDayAbsenceRequestService.absence.counters = dataVerification.counters;
          this.fullDayAbsenceRequestService.goToNextStep();
        }
      });
    }
  }

  openErrorModal(message: string = 'ABSENCE_REQUEST.MODAL.MESSAGE') {
    const modalRef = this.modalService.open(NotificationModalComponent, {
      centered: true
    });
    modalRef.componentInstance.title = 'ABSENCE_REQUEST.MODAL.TITLE';
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.icon = 'icon-alert-red';
  }

}
