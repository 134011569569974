import { DayType } from 'src/app/shared/enums/home/dayType.enum';
import { API_URLS } from 'src/environments/api-urls';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { SwapSearchShift, SwapShift } from 'src/app/features/swap/models/swapSearchShift';
import { Observable, of, forkJoin } from 'rxjs';
import { SwapRestSearch } from 'src/app/features/swap/models/swapSearch';
import { IControlReponse } from './interfaces/swapSearchControl';
import { map } from 'rxjs/operators';
import { SwapDetailsEmployee } from './models/swap-details';
import { SwapRequestSummary } from './models/swapSummary';
import { SwapTypeEnum } from './enums/requestType.enum';
import { UserService } from 'src/app/shared/services/user.service';
@Injectable()
export class RestStepsService {

  step = 1;
  continueStep = true;
  selectedServiceDate: moment.Moment;
  shiftId: number;
  selectedRestDate: moment.Moment;
  swapRestSearch = new SwapRestSearch();
  comment: string;
  userShift: SwapDetailsEmployee;
  userRest: any;
  swapSummary: SwapRequestSummary;
  message: string;
  title: string;

  constructor(
    private router: Router,
    private http: HttpClient,
    private userService: UserService
  ) { }

  goToPreviousStep() {
    this.step--;
    if (!this.step) {
      this.step = 1;
      this.router.navigate(['swap/new']);
    }
  }

  goToNextStep() {
    if (this.continueStep) {
      this.step++;
    }
  }

  searchControl(): Observable<IControlReponse> {
    const matriculeColleague = this.swapRestSearch.colleagueShift.adminNumber;
    return this.http.get<IControlReponse>(`${API_URLS.SWAP}/check-counter-balance/${matriculeColleague}`);
  }

  searchSimulationColleague(): Observable<boolean> {
    const adminNumber = this.swapRestSearch.colleagueShift.adminNumber; // adminNumber of colleague
    const date = this.selectedServiceDate.format('YYYY-MM-DD'); // working date
    const shiftId = this.shiftId.toString(); //  shiftId of current admin
    return this.http.get<boolean>( `${API_URLS.SWAP}/simulation?date=${date}&adminNumber=${adminNumber}&shiftId=${shiftId}`);
  }

  searchSimulationUser(): Observable<boolean> {
    const shift =  this.swapRestSearch.colleagueShift.shift;
    // if day type were rest, no simulation need to perform
    if ( shift && shift.type === DayType.REST ) {
      return of(true);
    }
    const adminNumber = this.userService.adminNumber; // adminNumber of user
    const date = this.selectedRestDate.format('YYYY-MM-DD'); // rest date
    const shiftId = this.swapRestSearch.colleagueShift.shift.id.toString(); // shiftId of colleague
    return this.http.get<boolean>( `${API_URLS.SWAP}/simulation?date=${date}&adminNumber=${adminNumber}&shiftId=${shiftId}`);
  }

  searchSimulation(): Observable<boolean> {
    if (this.selectedServiceDate && this.selectedRestDate) {
      return forkJoin([
        this.searchSimulationColleague(),
        this.searchSimulationUser()
      ]).pipe(
        map(results => {
          return results[0] && results[1];
        })
      );
    } else if (this.selectedServiceDate) {
      return this.searchSimulationColleague();
    } else {
      return this.searchSimulationUser();
    }
  }

  reset() {
    this.step = 1;
    this.selectedRestDate = null;
    this.selectedServiceDate = null;
    this.resetSwapSearch();
    this.comment = null;
  }

  resetSwapSearch() {
    this.swapRestSearch = new SwapRestSearch();
  }

  continueEnable(enabled: boolean) {
    this.continueStep = enabled;
  }

  selectServiceDate(date: moment.Moment) {
    this.selectedServiceDate = date;
  }

  setShiftId(id: number) {
    this.shiftId = id;
  }

  selectRestDate(date: moment.Moment) {
    this.selectedRestDate = date;
  }

  getSearchShift(): Observable<SwapSearchShift[]> {
    return this.http.post<SwapSearchShift[]>(`${API_URLS.SWAP}/rest-search`, {
      restDate: this.selectedRestDate ? this.selectedRestDate.format('YYYY-MM-DD') : null,
      workingDate: this.selectedServiceDate ? this.selectedServiceDate.format('YYYY-MM-DD') : null,
      searchTerm: this.swapRestSearch && this.swapRestSearch.searchTerm ? this.swapRestSearch.searchTerm : null
    });
  }

  setUserShift(meta: any) {
    const userAdminNumber = this.userService.adminNumber;
    const shift = new SwapShift(
      meta.shiftId,
      this.selectedServiceDate ? this.selectedServiceDate.toDate() : null,
      meta.type,
      meta.departure,
      meta.arrival,
      meta.workingTime,
      meta.reference  // *sender label
    );
    this.userShift = new SwapDetailsEmployee({
      adminNumber: userAdminNumber,
      firstName: this.userService.userFirstName,
      lastName: this.userService.userLastName,
      shift
    });
  }

  setUserRest(meta: any) {
    const userAdminNumber = this.userService.adminNumber;
    const shift = new SwapShift(
      meta.shiftId,
      this.selectedRestDate ? this.selectedRestDate.toDate() : null,
      meta.type,
      meta.departure,
      meta.arrival,
      meta.workingTime,
      meta.reference // *sender label
    );
    this.userRest = new SwapDetailsEmployee({
      adminNumber: userAdminNumber,
      firstName: this.userService.userFirstName,
      lastName: this.userService.userLastName,
      shift
    });
  }

  setSwapSummary() {
    this.swapSummary = new SwapRequestSummary(
      this.userShift ? this.userShift : this.userRest,
      new SwapDetailsEmployee({...this.swapRestSearch.colleagueShift}),
      this.selectedServiceDate ? this.selectedServiceDate.format('YYYY-MM-DD') : null,
      this.selectedRestDate ? this.selectedRestDate.format('YYYY-MM-DD') : null,
      SwapTypeEnum.REST,
      this.comment,
      this.message,
      this.title,
    );
  }

  sendSwapRequest(): Observable<void> {
    this.setSwapSummary();
    return this.http.post<void>(`${API_URLS.SWAP}/create`, this.swapSummary);
  }

}
