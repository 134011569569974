import * as moment from 'moment';

export class Task {
  title: string;
  remainingDays: number;
  remainingDaysToDisplay: string;
  limitDays: number;
  date: moment.Moment;

  constructor(title: string, remainingDays: number, limitDays: number, date: moment.Moment) {
    this.title = title;
    this.remainingDays = remainingDays;
    this.remainingDaysToDisplay = remainingDays < 0 ? `+${remainingDays.toString().substr(1)}` : `-${remainingDays}`;
    this.remainingDaysToDisplay = remainingDays === 0 ? this.remainingDaysToDisplay.replace('-', '') : this.remainingDaysToDisplay;
    this.limitDays = limitDays;
    this.date = date;
  }
}
