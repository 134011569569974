import { RestStepsService } from './rest-steps.service';
import { SwapCalendarComponent } from './swap-calendar/swap-calendar.component';
import { SwapRulesComponent } from './swap-rules/swap-rules.component';
import { SwapService } from './swap.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SwapComponent } from './swap.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NewSwapRequestComponent } from './new-swap-request/new-swap-request.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SwapHistoryComponent } from './swap-history/swap-history.component';
import { SwapRequestServiceComponent } from './swap-request-service/swap-request-service.component';
import { SwapStepsService } from './swap-steps.service';
import { SwapShiftSearchComponent } from './swap-request-service/swap-search/swap-search.component';
import { SwapRequestCalendarComponent } from './swap-request-calendar/swap-request-calendar.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SwapRequestRestComponent } from './swap-request-rest/swap-request-rest.component';
import { RestFirstCalendarComponent } from './swap-request-rest/rest-first-calendar/rest-first-calendar.component';
import { RestSecondCalendarComponent } from './swap-request-rest/rest-second-calendar/rest-second-calendar.component';
import { SwapCommentComponent } from './swap-comment/swap-comment.component';
import { SwapRestSearchComponent } from './swap-request-rest/swap-search/swap-search.component';
import { SwapDetailsComponent } from './swap-details/swap-details.component';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { SwapDetailsCardComponent } from './swap-details/swap-details-card/swap-details-card.component';
import { SwapSummaryComponent } from './swap-summary/swap-summary.component';
import { SwapServiceDetailComponent } from './swap-request-service/swap-search/swap-service-detail/swap-service-detail.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forRoot(),
        FormsModule,
        SharedModule,
        RouterModule,
        Nl2BrPipeModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        })
    ],
    declarations: [
        SwapComponent,
        NewSwapRequestComponent,
        SwapHistoryComponent,
        SwapRequestServiceComponent,
        SwapShiftSearchComponent,
        SwapRequestCalendarComponent,
        SwapCalendarComponent,
        SwapRulesComponent,
        SwapRequestRestComponent,
        RestFirstCalendarComponent,
        RestSecondCalendarComponent,
        SwapCommentComponent,
        SwapDetailsComponent,
        SwapRestSearchComponent,
        SwapDetailsCardComponent,
        SwapSummaryComponent,
        SwapServiceDetailComponent
    ],
    providers: [
        SwapService,
        SwapStepsService,
        RestStepsService
    ]
})
export class SwapModule { }
