import * as moment from 'moment';
import { SwapShift } from './swapSearchShift';

export class SwapDetailsEmployee {
    adminNumber: string;
    firstName: string;
    lastName: string;
    workingShift?: SwapShift;
    restShift?: SwapShift;
    shift?: SwapShift;

    get tte(): string {
        const hours = moment.utc(this.workingShift.workingTime * 60 * 1000).format('HH');
        const minutes = moment.utc(this.workingShift.workingTime * 60 * 1000).format('mm');
        return hours + 'h' + minutes;
    }

    constructor(args: {
        adminNumber: string;
        firstName: string;
        lastName: string;
        workingShift?: SwapShift;
        restShift?: SwapShift;
        shift?: SwapShift;
    }) {
        this.adminNumber = args.adminNumber;
        this.firstName = args.firstName;
        this.lastName = args.lastName;
        this.workingShift = args.workingShift;
        this.restShift = args.restShift;
        this.shift = args.shift;
    }
}


export class SwapDetails {
    id: number;
    date: string;
    state: string;
    sender: SwapDetailsEmployee;
    receiver: SwapDetailsEmployee;
    askedDate: string;
    exchangedDate: string;
    type: string;
    exploitComment?: string;
    agentComment?: string;
    remainingDate: string;
    message: string;
    title: string;

    constructor(args: {
        id: number;
        date: string;
        state: string;
        sender: SwapDetailsEmployee;
        receiver: SwapDetailsEmployee;
        askedDate: string;
        exchangedDate: string;
        type: string;
        exploitComment?: string;
        agentComment?: string;
        remainingDate: string;
        message: string;
        title: string;
    }) {
        this.id = args.id;
        this.date = args.date;
        this.state = args.state;
        this.sender = new SwapDetailsEmployee({ ...args.sender });
        this.receiver = new SwapDetailsEmployee({ ...args.receiver });
        this.askedDate = args.askedDate;
        this.exchangedDate = args.exchangedDate !== '9999-12-31' ? args.exchangedDate : null;
        this.type = args.type;
        this.exploitComment = args.exploitComment;
        this.agentComment = args.agentComment;
        this.remainingDate = args.remainingDate;
        this.message = args.message;
        this.title = args.title;
    }
}


