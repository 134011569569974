import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Counter } from 'src/app/shared/models/counters/counters';
import { Format } from 'src/app/shared/enums/counters/balanceFormat.enum';
import { AbsenceService } from '../absence.service';
import { LoaderModalService } from 'src/app/shared/services/loader-modal.service';

@Component({
  selector: 'app-counter-balance-modal',
  templateUrl: './counter-balance-modal.component.html',
  styleUrls: ['./counter-balance-modal.component.scss']
})
export class CounterBalanceModalComponent implements OnInit {

  @Input() date: moment.Moment;
  @Input() absenceType: 'PARTIAL' | 'FULL_DAY';
  counters: Counter[] = [];
  format = Format;

  constructor(
    private absenceService: AbsenceService,
    public activeModal: NgbActiveModal,
    public loaderModalService: LoaderModalService
  ) {}

  ngOnInit() {
    this.getCountersBalance();
  }

  getCountersBalance() {
    this.loaderModalService.show();
    this.absenceService.getCountersBalance(this.date.format('YYYY-MM-DD'), this.absenceType, null).subscribe((counters: Counter[]) => {
      this.counters = counters.map(counter => (new Counter({ ...counter })));
      this.loaderModalService.dismiss();
    }, () => {
      this.loaderModalService.dismiss();
    });
  }

}
