import { ShiftStatus } from '../../enums/home/shiftStatus.enum';
import {LocationAndTime} from '../planning/mission';


export class ShiftDetails {
    status: ShiftStatus;
    departure?: LocationAndTime;
    arrival?: LocationAndTime;
    vehicleReference: string;
    charterManifestCount: number;
    vehicleCodes: string[];


    constructor(
        status: ShiftStatus,
        departure: LocationAndTime,
        arrival: LocationAndTime,
        vehicleReference: string,
        charterManifestCount: number,
        vehicleCodes: string[]) {
            this.status = status;
            this.departure = departure;
            this.arrival = arrival;
            this.vehicleReference = vehicleReference;
            this.charterManifestCount = charterManifestCount;
            this.vehicleCodes = vehicleCodes;
        }
}
