import { BrowserModule } from '@angular/platform-browser';
import {NgModule, LOCALE_ID, APP_INITIALIZER} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {I18nHttpLoaderFactory} from './shared/utils/factories';
import {SharedModule} from './shared/shared.module';
import {registerLocaleData, CommonModule} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { TokenInterceptorService } from './shared/interceptor/token.interceptor.service';
import { WebsocketConnectionService } from 'src/app/shared/services/websocket-connection.service';
import { AuthenticationService } from './auth.service';
import { Initializable } from './shared/services/initializable';
import { HomeModule } from './features/home/home.module';
import { MessagesModule } from './features/messages/messages.module';
import { PlanningModule } from './features/planning/planning.module';
import { AbsenceModule } from './features/absence/absence.module';
import { DocumentsModule } from './features/documents/documents.module';
import { TrainingsModule } from './features/trainings/trainings.module';
import { CountersModule } from './features/counters/counters.module';
import { SwapModule } from './features/swap/swap.module';
import { VolunteerModule } from './features/volunteer/volunteer.module';
import { AccountModule } from './features/account/account.module';
import { NewsModule } from './features/news/news.module';
import { PrepayModule } from './features/prepay/prepay.module';
import { RouterModule } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ForbiddenAccessModule } from './features/forbidden-access/forbidden-access.module';
import {SurveysModule} from './features/surveys/surveys.module';
import { BadgeConnectionModule } from './features/badge-connection/badge-connection.module';
import { ForcedPasswordChangeModule } from './features/forced-password-change/forced-password-change.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

registerLocaleData(localeFr, 'fr');

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

export function appInit(service: Initializable) {
  return () => {
    return service.init();
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    RouterModule,
    HomeModule,
    CommonModule,
    MessagesModule,
    PlanningModule,
    AbsenceModule,
    DocumentsModule,
    TrainingsModule,
    CountersModule,
    SwapModule,
    VolunteerModule,
    AccountModule,
    ForbiddenAccessModule,
    BadgeConnectionModule,
    NewsModule,
    PrepayModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SwiperModule,
    SurveysModule,
    ForcedPasswordChangeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: I18nHttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
   // DeviceDetectorModule.forRoot(),
    SharedModule
  ],
  providers: [
    AuthenticationService,
    WebsocketConnectionService,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [AuthenticationService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
