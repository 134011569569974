import { DayType } from '../../enums/home/dayType.enum';
import { Shift } from './shift';
import * as moment from 'moment';
import { PeriodType } from '../../enums/home/periodType.enum';
import { LocationAndTime } from '../planning/mission';

export class HomeDayCalender {
  date: moment.Moment;
  month: number;
  type: DayType;
  code?: string;
  label?: string;
  workingTime?: boolean;
  reference?: string;
  recomposedShift: boolean;
  valid: boolean;
  numberOfPoints: number;
  period: PeriodType;
  parts: Array<Shift>;
  departure?: LocationAndTime;
  arrival?: LocationAndTime;
  estimated: boolean;
  duration?: number;
  passed?: boolean;
  link?: string;
  isCloudLink?: boolean;
  constructor(
    date: moment.Moment,
    type: DayType,
    code: string,
    workingTime: boolean,
    reference: string,
    recomposedShift: boolean,
    valid: boolean,
    numberOfPoints: number,
    period: PeriodType,
    parts: Array<Shift>,
    month = null,
    estimated: boolean = true,
  ) {
    this.date = date;
    this.type = type;
    this.code = code;
    this.workingTime = workingTime;
    this.recomposedShift = recomposedShift;
    this.valid = valid;
    this.numberOfPoints = numberOfPoints;
    this.period = period;
    this.parts = parts;
    this.month = month;
    this.estimated = estimated;
    this.reference = reference;
  }
}
