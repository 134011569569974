import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagesComponent } from './messages.component';
import { TranslateModule } from '@ngx-translate/core';
import { MessagesService } from './messages.service';
import {FormsModule} from '@angular/forms';
import { ModalComponent } from './modal/modal.component';
import { LinkyModule } from 'ngx-linky';
import { Nl2BrPipeModule } from 'nl2br-pipe';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forRoot(),
        FormsModule,
        LinkyModule,
        Nl2BrPipeModule,
        SharedModule
    ],
    declarations: [MessagesComponent, ModalComponent],
    providers: [MessagesService]
})
export class MessagesModule { }
