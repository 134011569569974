import { Component, OnInit } from '@angular/core';
import { WebsocketConnectionService } from 'src/app/shared/services/websocket-connection.service';

@Component({
  selector: 'app-badge-connection',
  templateUrl: './badge-connection.component.html',
  styleUrls: ['./badge-connection.component.scss']
})
export class BadgeConnectionComponent implements OnInit {

  constructor(
    private websocketService: WebsocketConnectionService,
  ) { }

  async ngOnInit() {
    await this.websocketService.init();
    this.sendReaderIDToWebsocket();
    this.reSendMessageWhenWebSocketClosed();
  }

  sendReaderIDToWebsocket() {
    const defaultDevice = localStorage.getItem('default_device');
    if (defaultDevice) {
      const readerId = JSON.parse(defaultDevice).readerId;
      const message = JSON.stringify({ readerId });
      this.websocketService.sendMessage(message);
    }
  }

  reSendMessageWhenWebSocketClosed() {
    this.websocketService.WebSocketReOpened.subscribe(() => {
      this.sendReaderIDToWebsocket();
    });
  }
}
