import { Injectable } from '@angular/core';
import { UnreadMessagesCountResponse } from '../models/message/UnreadMessagesCountResponse';
import { HttpClient } from '@angular/common/http';
import { API_URLS } from 'src/environments/api-urls';

@Injectable()

export class MessagesCountService {

  unreadMessageCount = 0;
  unreadExploitMessageCount = 0;
  unreadOtherMessageCount = 0;
  pendingSwapRequestCount = 0;

  constructor(private http: HttpClient) {}

  getUnreadMessageCount() {
    return this.http.get<UnreadMessagesCountResponse>(API_URLS.MESSAGE_NOTIFICATION).subscribe(data => {
      this.unreadMessageCount = data.unreadMessageCount;
      this.unreadExploitMessageCount = data.unreadExploitMessageCount;
      this.unreadOtherMessageCount = data.unreadOtherMessageCount;
      this.pendingSwapRequestCount = data.pendingSwapRequestCount;
    });
  }

}
