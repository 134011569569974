import { MessageStatus } from '../../enums/message/messageStatus.enum';
import { MessageType } from '../../enums/message/messageType.enum';
import * as moment from 'moment';


export class Message {
    id: number;
    archived: boolean;
    title: string;
    content: string;
    creationDate: moment.Moment;
    favorite: boolean;
    markedRead: boolean;
    sender: string;
    type: MessageType;
    status: MessageStatus;
    showActions: boolean;
    concernDate: moment.Moment;
    url?: string;

    constructor(
        id: number, archived: boolean, title: string, content: string, creationDate: moment.Moment,
        favorite: boolean, markedRead: boolean, sender: string, type: MessageType, status: MessageStatus, concernDate: moment.Moment,
        url?: string) {

        this.id = id;
        this.archived = archived;
        this.title = title;
        this.content = content;
        this.creationDate = creationDate;
        this.favorite = favorite;
        this.markedRead = markedRead;
        this.sender = sender;
        this.type = type;
        this.status = status;
        this.showActions = false;
        this.concernDate = concernDate;
        this.url = url;
    }
}

export class MessageUpdatedPart {
    favorite: boolean;
    archived: boolean;
    markedRead: boolean;
    status: MessageStatus;
}
export class MessageResponse {
    messages: Array<Message>;
    hasNext: boolean;
}
