import { SwapDetailsEmployee } from './swap-details';
import { SwapTypeEnum } from '../enums/requestType.enum';

export class SwapRequestSummary {
    sender: SwapDetailsEmployee;
    receiver: SwapDetailsEmployee;
    askedDate: string;
    exchangedDate: string;
    type: SwapTypeEnum;
    agentComment: string;
    message: string;
    title: string;

    constructor(
        sender: SwapDetailsEmployee,
        receiver: SwapDetailsEmployee,
        askedDate: string,
        exchangedDate: string,
        type: SwapTypeEnum,
        agentComment: string,
        message: string,
        title: string
    ) {
        this.sender = sender;
        this.receiver = receiver;
        this.askedDate = askedDate;
        this.exchangedDate = exchangedDate;
        this.type = type;
        this.agentComment = agentComment;
        this.message = message;
        this.title = title;
    }
}
