import { Component } from '@angular/core';
import { Tabs } from 'src/app/shared/interfaces/tabs.interface';

@Component({
  selector: 'app-absence',
  templateUrl: './absence.component.html',
  styleUrls: ['./absence.component.scss']
})
export class AbsenceComponent {

  absenceTabs: Tabs[] = [
    {
      title : 'ABSENCE_REQUEST.NEW_REQUEST_TAB',
      link : '/absence'
    },
    {
      title : 'ABSENCE_REQUEST.HISTORY_TAB',
      link : '/absence/history'
    }
  ];

  constructor() {
  }
}
