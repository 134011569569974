import { VolunteerComponent } from './features/volunteer/volunteer.component';
import { SwapRequestRestComponent } from './features/swap/swap-request-rest/swap-request-rest.component';
import { PartialAbsenceRequestComponent } from './features/absence/partial-absence-request/partial-absence-request.component';
import { SwapComponent } from './features/swap/swap.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './features/home/home.component';
import { MessagesComponent } from './features/messages/messages.component';
import { DocumentsComponent } from './features/documents/documents.component';
import { TrainingsComponent } from './features/trainings/trainings.component';
import { PlanningComponent } from './features/planning/planning.component';
import { PlanningDetailsComponent } from './shared/components/planning-details/planning-details.component';
import { AbsenceComponent } from './features/absence/absence.component';
import { AbsenceHomeComponent } from './features/absence/home/absence-home.component';
import { AbsenceHistoryComponent } from './features/absence/history/absence-history.component';
import { CountersComponent } from './features/counters/counters.component';
import { CounterHistoryComponent } from './features/counters/counter-history/counter-history.component';
import { SwapHistoryComponent } from './features/swap/swap-history/swap-history.component';
import { SwapRequestServiceComponent } from './features/swap/swap-request-service/swap-request-service.component';
import { NewSwapRequestComponent } from './features/swap/new-swap-request/new-swap-request.component';
import { FullDayAbsenceRequestComponent } from './features/absence/fullDay-absence-request/fullDay-absence-request.component';
import { AbsenceDetailsComponent } from './features/absence/details/details.component';
import { SwapDetailsComponent } from './features/swap/swap-details/swap-details.component';
import { SwapServiceAccess } from './shared/services/routes-activate/swap-service-access.service';
import { SwapRestAccess } from './shared/services/routes-activate/swap-rest-access.service';
import { AccountComponent } from './features/account/account.component';
import { NewsRoutes } from './features/news/news.routing';
import { DetailsComponent as trainingsDetailsComponent } from './features/trainings/details/details.component';
import { DetailsComponent } from './features/documents/details/details.component';
import { PrepayComponent } from './features/prepay/prepay.component';
import { ForbiddenAccessComponent } from './features/forbidden-access/forbidden-access.component';
import { SurveysRoutes } from './features/surveys/surveys.routing';
import { BadgeConnectionComponent } from './features/badge-connection/badge-connection.component';
import { MainLayoutComponent } from './shared/layout/main-layout/main-layout.component';
import { PrepayDetailsComponent } from './features/prepay/prepay-details/prepay-details.component';
import { PrepayDescriptionComponent } from './features/prepay/prepay-description/prepay-description.component';
import { MessageAccessService } from './shared/services/routes-activate/message-access.service';
import { DocumentsAccessService } from './shared/services/routes-activate/documents-access.service';
import { TrainingsAccessService } from './shared/services/routes-activate/trainings-access.service';
import { PrepayAccessService } from './shared/services/routes-activate/prepay-access.service';
import { AbsenceAccessService } from './shared/services/routes-activate/absence-access.service';
import { VolunteerAccessService } from './shared/services/routes-activate/volunteer-access.service';
import { CountersAccessService } from './shared/services/routes-activate/counters-access.service';
import { SwapAccessService } from './shared/services/routes-activate/swap-access.service';
import { AbsenceRequestFullAccessService } from './shared/services/routes-activate/absence-request-full-access.service';
import { AbsenceRequestPartialAccessService } from './shared/services/routes-activate/absence-request-partial-access.service';
import { NewsAccessService } from './shared/services/routes-activate/news-access.service';
import { SurveysAccessService } from './shared/services/routes-activate/surveys-access.service';
import { UserGuardResolver } from './shared/services/user-guard.service';
import { ParamsAccessService } from './shared/services/routes-activate/params-access.service';
import { ForcedPasswordChangeComponent } from './features/forced-password-change/forced-password-change.component';
import { ForcedPasswordChangeCanActivateService } from './features/forced-password-change/canactivate.service';

export const routes: Routes = [

  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [UserGuardResolver],
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: 'profile',
        component: AccountComponent,
      },
      {
        path: 'messages',
        component: MessagesComponent,
        canActivate: [MessageAccessService],
      },
      {
        path: 'documents',
        canActivate: [DocumentsAccessService],
        children: [
          {
            path: '',
            component: DocumentsComponent
          },
          {
            path: 'details',
            component: DetailsComponent
          }
        ]
      },
      {
        path: 'trainings',
        component: TrainingsComponent,
        canActivate: [TrainingsAccessService],
      },
      {
        path: 'trainings/details',
        component: trainingsDetailsComponent,
        canActivate: [TrainingsAccessService],
      },
      {
        path: 'planning',

        children: [
          {
            path: '',
            component: PlanningComponent
          },
          {
            path: 'details',
            component: PlanningDetailsComponent
          }
        ]
      },
      {
        path: 'prepay',
        canActivate: [PrepayAccessService],
        children: [
          {
            path: '',
            component: PrepayComponent
          },
          {
            path: 'details/:start/:end',
            component: PrepayDetailsComponent
          }
          ,
          {
            path: 'description',
            component: PrepayDescriptionComponent
          }
        ]
      },
      {
        path: 'absence',
        component: AbsenceComponent,
        canActivate: [AbsenceAccessService],
        children: [
          {
            path: '',
            component: AbsenceHomeComponent,
          },
          {
            path: 'history',
            component: AbsenceHistoryComponent
          }
        ]
      },
      {
        path: 'absence/details/:id',
        component: AbsenceDetailsComponent,
        canActivate: [AbsenceAccessService],
      },
      {
        path: 'absence-request',
        canActivate: [AbsenceAccessService],
        children: [
          {
            path: 'partial',
            component: PartialAbsenceRequestComponent,
            canActivate: [AbsenceRequestPartialAccessService],
          },
          {
            path: 'full',
            component: FullDayAbsenceRequestComponent,
            canActivate: [AbsenceRequestFullAccessService],
          }
        ]
      },
      {
        path: 'volunteer',
        component: VolunteerComponent,
        canActivate: [VolunteerAccessService],
      },
      {
        path: 'counters',
        canActivate: [CountersAccessService],
        children: [
          {
            path: '',
            component: CountersComponent
          },
          {
            path: ':code',
            component: CounterHistoryComponent
          }
        ]
      },
      {
        path: 'swap',
        component: SwapComponent,
        canActivate: [SwapAccessService],
        children: [
          {
            path: '',
            redirectTo: 'new',
            pathMatch: 'full'
          },
          {
            path: 'new',
            component: NewSwapRequestComponent
          },
          {
            path: 'history',
            component: SwapHistoryComponent
          }
        ]
      },
      {
        path: 'swap/details/:id',
        component: SwapDetailsComponent,
        canActivate: [SwapAccessService],
      },
      {
        path: 'swap-request',
        canActivate: [SwapAccessService],
        children: [
          {
            path: '',
            redirectTo: 'service',
            pathMatch: 'full'
          },
          {
            path: 'service',
            component: SwapRequestServiceComponent,
            canActivate: [SwapServiceAccess]
          },
          {
            path: 'rest',
            component: SwapRequestRestComponent,
            canActivate: [SwapRestAccess]
          }
        ]
      },

      NewsRoutes,
      SurveysRoutes
    ]
  },
  {
    path: 'params',
    canActivate: [ParamsAccessService],
    children: []
  },
  {
    path: 'forbidden-access',
    component: ForbiddenAccessComponent
  },
  {
    path: 'badge-authentication',
    component: BadgeConnectionComponent,
    canLoad: [UserGuardResolver]
  },
  {
    path: 'password-change',
    canActivate: [ForcedPasswordChangeCanActivateService],
    component: ForcedPasswordChangeComponent
  },
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [
    SwapServiceAccess,
    SwapRestAccess,
    MessageAccessService,
    DocumentsAccessService,
    TrainingsAccessService,
    PrepayAccessService,
    AbsenceAccessService,
    VolunteerAccessService,
    CountersAccessService,
    SwapAccessService,
    SurveysAccessService,
    NewsAccessService,
    AbsenceRequestFullAccessService,
    AbsenceRequestPartialAccessService,
    ForcedPasswordChangeCanActivateService,
    UserGuardResolver,
    ParamsAccessService,
  ]
})
export class AppRoutingModule {
}
