import { WorkOutsideDepartments } from 'src/app/shared/models/home/punchInOutConditions';
import { PunchInOutTypeEnum } from './../../../shared/enums/home/punchInOutType.enum';
import { HomeService } from 'src/app/features/home/home.service';
import { SelectableItem } from 'src/app/shared/interfaces/selectableItem';
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { ISavedPunchInOuts } from './punchInOut.interface';

@Component({
  selector: 'app-punch-in-out-conditions',
  templateUrl: './punchInOutConditions.component.html',
  styleUrls: ['./punchInOutConditions.component.scss']
})
export class PunchInOutConditionsComponent implements OnInit {
  @Input() date: string;

  punchInOutTypeEnum = PunchInOutTypeEnum;
  punchInOutConditionsForm: UntypedFormGroup;
  workOutsideDepartments: UntypedFormArray;
  departments: SelectableItem[] = [];
  currentlyCheckOutChecked: string;
  savedPunchInOuts: ISavedPunchInOuts[] = [];
  sendPunchInOutData: WorkOutsideDepartments[] = [];


  constructor(
    public activeModal: NgbActiveModal,
    private homeService: HomeService,
    private formBuilder: UntypedFormBuilder,
    private snackbarService: SnackbarService
    ) { }

  ngOnInit() {
    this.getSavedPunchInOuts();
    this.getDepartmentsData();
    this.initPunchInOutConditionsForm();
  }

  getSavedPunchInOuts() {
    this.homeService.getSavedPunchInOuts(this.date).subscribe(data => {
      this.savedPunchInOuts = data;
    });
  }

  getDepartmentsData() {
    this.homeService.getDepartmentsData().subscribe(departments => {
      this.departments = departments;
    });
  }

  initPunchInOutConditionsForm() {
    this.punchInOutConditionsForm = this.formBuilder.group({
      type: [this.punchInOutTypeEnum.IN],
      workOutsideDepartments: this.formBuilder.array([
        this.createWorkOutsideDepartmentsControl()
      ])
    });
  }

  createWorkOutsideDepartmentsControl() {
    return this.formBuilder.group({
      startHour : null,
      endHour : {value: null, disabled: false},
      departmentCode: null,
      lastCheckOut: false,
    });
  }

  selectCheckOutChecked(checkbox: string) {
    const checkBoxIndex = Number(checkbox.charAt(checkbox.length - 1));
    const selectedEndHour = (this.punchInOutConditionsForm.get('workOutsideDepartments') as UntypedFormArray).at(checkBoxIndex).get('endHour');
    if (this.currentlyCheckOutChecked === checkbox) {
      this.currentlyCheckOutChecked = '';
      selectedEndHour.reset();
      selectedEndHour.enable();
      return;
    }
    this.currentlyCheckOutChecked = checkbox;
    selectedEndHour.reset();
    selectedEndHour.setValue({
      hour: new Date().getHours(),
      minute: new Date().getMinutes()
    });
    selectedEndHour.disable();
  }

  addWorkOutsideDepartments() {
    this.workOutsideDepartments = this.punchInOutConditionsForm.get('workOutsideDepartments') as UntypedFormArray;
    this.workOutsideDepartments.push(this.createWorkOutsideDepartmentsControl());
  }

  deleteWorkOutsideDepartments(index: number) {
    const form = this.punchInOutConditionsForm.get('workOutsideDepartments') as UntypedFormArray;
    form.length > 1 ? form.removeAt(index) : this.punchInOutConditionsForm.controls.workOutsideDepartments.reset();
    this.punchInOutConditionsForm.get('workOutsideDepartments').updateValueAndValidity();
  }


  validate() {
    this.savePunchInOutConditions();
  }

  savePunchInOutConditions() {
    if (this.punchInOutConditionsForm.controls.type.value === this.punchInOutTypeEnum.IN ) {
      this.homeService.validatePunchInConditions(this.date).subscribe(
        () => {
          this.snackbarService.show('PUNCH_IN_OUT.CONDITIONS.PUNCH_IN_SUCCESS', 'success');
          this.activeModal.close();
        },
        () => {
          this.snackbarService.show('PUNCH_IN_OUT.CONDITIONS.PUNCH_IN_OUT_ERROR', 'error');
        }
      );
    } else {
      this.sendPunchInOutData = this.punchInOutConditionsForm.controls.workOutsideDepartments.value;
      this.homeService.validatePunchOutConditions(this.date, this.sendPunchInOutData).subscribe(
        () => {
          this.snackbarService.show('PUNCH_IN_OUT.CONDITIONS.PUNCH_OUT_SUCCESS', 'success');
          this.activeModal.close();
        },
        () => {
          this.snackbarService.show('PUNCH_IN_OUT.CONDITIONS.PUNCH_IN_OUT_ERROR', 'error');
        }
      );
    }
  }
}
