import { MissionType } from '../../enums/planning/missionType.enum';

export class LocationAndTime {
  date: string;
  location: string;
  dayBefore?: boolean;
  dayAfter?: boolean;


  constructor(date: string, location: string, dayBefore: boolean, dayAfter: boolean) {
    this.date = date;
    this.location = location;
    this.dayBefore = dayBefore;
    this.dayAfter = dayAfter;
  }
}

export class Mission {
  label: string;
  color: string;
  subLabel?: string;
  type: MissionType;
  departure: LocationAndTime;
  arrival: LocationAndTime;
  lineNumber: string;
  vehicle?: Vehicle;
  missionReference: string;
  stops?: Stop[];
  drivingMission: boolean;
  displayStops?: boolean;
  operationCode: string;
  charterNumber: string;
  missionLabel: string;
  observation?: string;
}

class Vehicle {
  vehicleCode: string;
  other?: string;
}

class Stop {
  date: string;
  label: string;
  arrival: {
    date: string;
    dayAfter: boolean;
    location: string;
  };
}
