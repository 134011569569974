import { CounterSettings } from './../../shared/models/settings/counterSettings/counterSettings';
import { Component, OnInit } from '@angular/core';
import { CountersService } from './counters.service';
import * as moment from 'moment';
import { Counter } from 'src/app/shared/models/counters/counters';
import { UserService } from 'src/app/shared/services/user.service';
import { Format } from 'src/app/shared/enums/counters/balanceFormat.enum';
import { BalanceDisplaying } from 'src/app/shared/enums/setting/counterSettings/balanceDisplaying.enum';
import { LoaderModalService } from 'src/app/shared/services/loader-modal.service';

@Component({
  selector: 'app-counters',
  templateUrl: './counters.component.html',
  styleUrls: ['./counters.component.scss']
})
export class CountersComponent implements OnInit {

  moment: any = moment;
  counters: Counter[] = [];
  errorGetResults: boolean;
  loadingResults = true;
  balanceDisplaying = BalanceDisplaying;
  format = Format;
  counterSettings: CounterSettings;


  constructor(
    private countersService: CountersService,
    private loaderService: LoaderModalService,
    public userService: UserService
  ) { }

  ngOnInit() {
    this.userService.typologySetting.subscribe(typoSettings => {
      this.counterSettings = typoSettings.counterSettings;
    });
    this.getCounters();
  }

  getCounters() {
    this.loaderService.show();
    this.countersService.getCounters().subscribe((counters: Counter[]) => {
      this.counters = counters.map(counter => (new Counter({...counter})));
      this.loadingResults = false;
      this.loaderService.dismiss();
    },
    () => {
      this.errorGetResults = true;
      this.loadingResults = false;
      this.loaderService.dismiss();
    });
  }
}
