/**
 * The base class for all models
 *  BaseModel
 */
export class BaseModel {
  /**
   * {object} params
   */
  constructor(params) {
    this._fillProperties(params);
  }

  /**
   * This function will set the values of all this instance
   * properties passed via params.
   */
  private _fillProperties(params) {
    params = params || {};
    for (const property  in params) {
      if (params.hasOwnProperty(property)) {
        this[property] = params[property];
      }
    }
  }

  /**
   * Returns a json presentation of the model
   * #Future_usage
   */
  toJSON(params) {
    throw new Error('Not implemented');
  }
}
