import { GIT_URLS } from './../../../../environments/environment';
import { HeaderProfiles } from './../../enums/header/headerProfiles.enum';
import { UserService } from 'src/app/shared/services/user.service';
import { Component, OnInit } from '@angular/core';
import { HeaderInfos } from '../../models/header/headerInfos';
import * as moment from 'moment-timezone';
import { AuthenticationService } from 'src/app/auth.service';
import { countries } from '../../constants/constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  mouseHovered = false;
  headerProfiles = HeaderProfiles;
  infos: HeaderInfos = {
    version: '0.0.0',
    buildNumber: '0',
    activeProfiles: [this.headerProfiles.TESTING],
    buildTime: '0',
    lastCommit: '0',
    branch: '0'
  };
  isProd: boolean;
  gitUrl = GIT_URLS.REPO_MOBINEXT_COMMIT;
  countryName = countries.find(c => c.code === localStorage.getItem('country')).label;
  date: any;

  constructor(private authenticationService: AuthenticationService, public userService: UserService) {}

  ngOnInit() {
    this.userService.getInfos().subscribe(infos => {
      this.infos = infos;
      this.isProd = this.isProdProfile();
      this.infos.buildTime = moment(this.infos.buildTime).format('DD/MM/YYYY HH:mm');
    });
    this.seDate();
  }

  logout() {
    this.authenticationService.logout();
  }

  isProdProfile(): boolean {
    return (
      this.infos.activeProfiles.findIndex(profile => {
        return profile === this.headerProfiles.PROD;
      }) !== -1
    );
  }

  get firstNameFirstLetter() {
    return this.userService.userFirstName.charAt(0);
  }

  seDate() {
    setInterval(() => {
      this.date = moment()
      .locale(this.userService.site.language.code)
      .tz(this.userService.site.timeZone);
    }, 1000);
  }
}
