import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { API_URLS } from 'src/environments/api-urls';
import { dateFormatslanguages } from '../constants/constants';
import { HeaderInfos } from '../models/header/headerInfos';
import { Settings, Site, UserAttributes } from '../models/settings/settings';
import { TypologyDeviceSettings, TypologySettings } from './../models/settings/settings';
import { AnalyticsService } from './GTM/analytics.service';
import { PartialCustomDimensionsSet } from '../types/customDimensions';
import { DataLayerCustomDimensions } from '../enums/GTM/dataLayer.enum';
import { Title } from '@angular/platform-browser';
import { Md5 } from 'ts-md5';
import { NotificationChoice } from '../models/notification-choice/notification-choice';
import { CustomFunctionSettings } from '../models/settings/customFunctionsSettings/customFunctionsSettings';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  public site: Site = {
    firstDayOfWeek: 1,
    language: {
      code: 'fr-FR',
      dateFormat: {
        mini: 'DD-MM', // 20/10
        short: 'DD/MM/YYYY', // 20/10/2019
        medium: 'DD MMMM YYYY', // 20 octobre 2019
        long: 'dddd DD MMMM YYYY', // dimanche 20 octobre 2019
      }
    },
    timeZone: 'Europe/Paris',
    siteTime: null
  };

  driver: boolean;
  userFirstName: string;
  userLastName: string;
  login: string;
  dbName: string;
  userSite: string;
  adminNumber: string;
  hashedLogin: string;
  userAttributes: UserAttributes;
  userAccess: ReplaySubject<{mobimeAccess: boolean, mobimeDisabled: boolean}> = new ReplaySubject();

  public typologyDeviceSettings: ReplaySubject <TypologyDeviceSettings> = new ReplaySubject();
  public shiftSettings: any = {};
  public typologySetting: ReplaySubject<TypologySettings> = new ReplaySubject();
  public customFunctionSettings: ReplaySubject<CustomFunctionSettings> = new ReplaySubject();
  token: string;
  accessToken: string;
  md5 = new Md5();

  defaultDevice: {
    id: number;
    deviceCode: string;
    type: 'DESKTOP' | 'MOBILE' | 'OTHER';
    readerId: string;
    default: boolean;
    allowToBeDelete: boolean;
  } = {
    id: 0,
    deviceCode: '',
    type: 'DESKTOP',
    readerId: '',
    default: false,
    allowToBeDelete: true
  };

  private analyticsService: AnalyticsService;
  forceSetPassword = false;

  constructor(
    private injector: Injector,
    private http: HttpClient,
    private translate: TranslateService,
    private titleService: Title,
  ) { }

  get dataLayerSet(): PartialCustomDimensionsSet {
    return {
      [DataLayerCustomDimensions.Language_DB]: this.site.language.code,
      [DataLayerCustomDimensions.Database_FDS]: this.dbName,
      [DataLayerCustomDimensions.Area]: this.userAttributes.area ? this.userAttributes.area.code : null,
      [DataLayerCustomDimensions.Staff_Category]: this.userAttributes.staffCategoryCode,
      [DataLayerCustomDimensions.WorkingGroup]: this.userAttributes.workingGroupCode,
      [DataLayerCustomDimensions.Affectation_Category]: this.userAttributes.affectationCategoryCode,
      [DataLayerCustomDimensions.Site_time]: this.site.siteTime,
      [DataLayerCustomDimensions.Login]: this.hashedLogin,
      [DataLayerCustomDimensions.Contract_type]: this.userAttributes.contractTypeCode,
      [DataLayerCustomDimensions.Roster_grid]: this.userAttributes.rosterGridCode,
      [DataLayerCustomDimensions.Main_habilitation]: this.userAttributes.mainHabilitationCode,
      [DataLayerCustomDimensions.Authentication_kind]: this.getAuthenticationKind(),
      // other data
    };
  }

  getUserSettings(): Observable<Settings> {
    let defaultDevice = null;
    try {
      defaultDevice = JSON.parse(localStorage.getItem('default_device'));
    } catch (error) {}

    if (defaultDevice) {
      this.defaultDevice = {
        id: defaultDevice.id || 0,
        type: defaultDevice.type || 'DESKTOP',
        readerId: defaultDevice.readerId || '',
        default: !!defaultDevice.default,
        allowToBeDelete: !!defaultDevice.allowToBeDelete ,
        deviceCode: defaultDevice.name || '',       // replace name by deviceCode
      };
    }
    const deviceCode = this.defaultDevice.deviceCode;
    const deviceType = this.defaultDevice.type;
    return this.http.get<Settings>
    (`${API_URLS.ENVIRONMENT}?deviceId=${this.defaultDevice.id}&deviceType=${deviceType}&deviceCode=${deviceCode}`);
  }

  getUserDetails(): Observable<any> {
    return this.http.get<any>(API_URLS.USER_DETAILS);
  }

  getUserNotificationChoice(): Observable<any> {
    return this.http.get<any>(API_URLS.GET_NOTIFICATIONS);
  }

  saveUserNotificationCjoice(notif: NotificationChoice ): Observable<any> {
    return this.http.put(API_URLS.SAVE_NOTIFICATION, notif);
  }

  setUserSettings() {
    this.getUserSettings().subscribe(userSetting => {
      if (userSetting) {
        this.setSettings(userSetting);
        this.analyticsService = this.injector.get(AnalyticsService);
        this.analyticsService.updateCustomDimensions(this.dataLayerSet);
        this.analyticsService.dataLayerPush();
      }
    });
  }

  setSettings(userSetting: Settings) {
    this.typologyDeviceSettings.next(userSetting.typologyDeviceSettings);
    this.typologySetting.next(userSetting.typologySettings);
    this.customFunctionSettings.next(userSetting.typologySettings.customFunctionSettings);
    this.setSiteSetting(userSetting.site);
  }

  setSiteSetting(site: Site) {
    this.mapFirstDayOfWeek(site.firstDayOfWeek);
    this.mapDateFormat(site.language.code);
    localStorage.setItem('language', site.language.code);
    this.site.timeZone = site.timeZone;
    this.site.siteTime = site.siteTime;

    // Do not call translations API again if it's the same as default
    if (this.site.language.code !== site.language.code) {
      this.site.language.code = site.language.code;
      this.translate.setDefaultLang(site.language.code);
      this.setAppTitleByLang();
    }

  }

  initDefaultLang() {
    const navLang = navigator.language;
    const supportedLanguages = ['fr-FR', 'fr-CA', 'en-AU', 'en-IE', 'en-NZ', 'en-US'];
    const defaultLang = supportedLanguages.includes(navLang) ? navLang : 'en-US';

    this.site.language.code = defaultLang;
    this.translate.setDefaultLang(defaultLang);
    this.setAppTitleByLang();
  }

  setUserDetails(loginData: any) {
    this.shiftSettings = loginData.typology && loginData.typology.settings ? loginData.typology.settings.shiftSettings : null;
    this.driver = loginData.typology ? loginData.typology.driverRequired : false;
    this.userFirstName = loginData.firstName;
    this.userLastName = loginData.lastName;
    this.login = loginData.account.login.value;
    this.dbName = loginData.account.dbName;
    this.userSite = loginData.account.site;
    this.adminNumber = loginData.adminNumber;
    this.userAttributes = loginData.attributes;
    this.hashedLogin = this.md5.appendStr(this.login).end().toString();
    this.userAccess.next({mobimeAccess: loginData.account.mobimeAccess, mobimeDisabled: loginData.account.mobimeDisabled});
    this.forceSetPassword = loginData.account.forceSetPassword;
  }

  setAppTitleByLang() {
    this.translate.get('APP_NAME').subscribe((appName: string) => {
      this.titleService.setTitle(appName);
    });
  }

  mapDateFormat(languageCode: string) {
    this.site.language.dateFormat = dateFormatslanguages.find(dateFormatsLang => dateFormatsLang.code === languageCode);
  }

  mapFirstDayOfWeek(firstDayOfWeek: number): number {
    if (firstDayOfWeek === 7) {
      // (dimanche =7) c'est la valeur enregistrée en base de données
      // 0 c'est la valeur mappée pour l'afficher dans le calendrier
      return this.site.firstDayOfWeek = 0;
    }
    return this.site.firstDayOfWeek = firstDayOfWeek;
  }

  getInfos(): Observable<HeaderInfos> {
    return this.http.get<HeaderInfos>(`${API_URLS.INFOS}`);
  }

  getAuthenticationKind(): string {
    return localStorage.getItem('badgeToken') ? 'badge' : 'AD' ;
  }

}
