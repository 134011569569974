import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnDestroy } from '@angular/core';
import { Tabs } from 'src/app/shared/interfaces/tabs.interface';
import { SwapRulesComponent } from './swap-rules/swap-rules.component';

@Component({
  selector: 'app-swap',
  templateUrl: './swap.component.html',
  styleUrls: ['./swap.component.scss']
})
export class SwapComponent {

  constructor(private modalService: NgbModal) {}

  swapTabs: Tabs[] = [
    {
      title : 'SWAP_REQUEST.NEW_REQUEST_TAB',
      link : '/swap/new'
    },
    {
      title : 'SWAP_REQUEST.HISTORY_TAB',
      link : '/swap/history'
    }
  ];

  openRules() {
    this.modalService.open(SwapRulesComponent, { centered: true, size: 'xl', scrollable: true});
  }
}
