export class Counter {
  code: string;
  label: string;
  isForecast: boolean;
  format: string;
  unit: string;
  balance: number;
  date?: Date;
  type?: string;
  pending?: number;

  get decimal(): string {
    const hours = this.balance / 60 ;
    const decimal = hours.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    return decimal;
  }

  constructor(args: {
    code: string;
    label: string;
    isForecast: boolean;
    format: string;
    balance: number;
    date?: Date;
    type?: string;
    pending?: number;
    unit?: string;
  }) {
    this.code = args.code;
    this.label = args.label;
    this.isForecast = args.isForecast;
    this.format = args.format;
    this.balance = args.balance;
    this.date = args.date;
    this.type = args.type;
    this.pending = args.pending;
    this.unit = args.unit;
  }
}

