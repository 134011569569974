import { Component, Input, Output, EventEmitter, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { DocumentType } from '../../../shared/enums/document/documentType.enum';
import { Document } from '../../../shared/models/document/document';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DocumentListService } from '../document-list.service';

@Component({
  selector: 'app-documents-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class FoldersListComponent implements AfterViewInit, OnInit {
  @Input() navigate: boolean;
  @Output() selectedFolder = new EventEmitter();
  documents: Document[] = [];
  DocumentType = DocumentType;
  displayedColumns: string[] = ['name', 'type', 'description', 'creationDate'];
  dataSource: MatTableDataSource<Document> = new MatTableDataSource<Document>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router, public service: DocumentListService) { }

  ngOnInit(): void {
    this.loadDocuments();
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.dataSource.paginator = this.paginator;
    }
    this.dataSource.sort = this.sort;
  }

  /**
   * Add path column in case we are in search mode
   */
  setColumns(): void {
    if (this.service.isSearchMode) {
      if (!this.displayedColumns.includes('path')) {
        this.displayedColumns = [
          ...this.displayedColumns.slice(0, 2),
          'path',
          ...this.displayedColumns.slice(2)
        ];
      }
    }
    else {
      if (this.displayedColumns.includes('path')) {
        this.displayedColumns = [...this.displayedColumns.filter(col => col !== 'path')];
      }
    }
  }

  /**
   * Set colomuns and get documents from backend
   */
  loadDocuments(): void {
    this.setColumns();
    this.service.documents$.subscribe(() => {
      this.documents = this.service.documents;
      this.dataSource.data = [...this.documents];
    });
  }

  getSelectedDocument(document: Document) {
    document.folder
      ? this.selectedFolder.emit(document)
      : this.router.navigate(['/documents/details'], {
        queryParams: {
          path: document.path,
          size: document.size,
          name: document.name,
          creationDate: document.creationDate,
          lastModified: document.lastModified,
          type: document.type,
          description: document.description
        }
      });
  }
}
