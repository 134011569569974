import { Injectable } from '@angular/core';
import { SurveyModel } from 'src/app/shared/models/surveys/survey.model';
import { LoaderModalService } from 'src/app/shared/services/loader-modal.service';
import { SurveysWsService } from 'src/app/shared/services/surveys.ws.service';


@Injectable()
export class SurveysService {
  /**
   * All fetched items
   */
  list: Array<SurveyModel> = [];
  /**
   * activeSurvey
   */
  activeSurveyDetails: SurveyModel;
  /**
   * Indicates whether the service is about
   * to fetch items from the server
   */
  isLoadingList = false;
  /**
   * Indicates whether the service is about
   * to fetch item
   */
  isLoadingDetails = false;
  /**
   * Indicates whether the service is about
   * to send response to server
   */
  isSavingData: boolean;


  constructor(
    private surveysApiService: SurveysWsService,
    private loaderService: LoaderModalService
  ) {

  }

  /**
   * Rest list function, used to reset page index and items list
   * FUTURE_USAGE
   */
  resetList(searchParams: {} = {}) {
    this.list = [];
  }

  /**
   * Function to fetch more items
   */
  fetchMore(searchParams: {} = {}) {
    this._fetchList();
  }


  /**
   * Fetch more items in the list
   * This function is used internally only
   */
  private _fetchList() {
    // empty it for now
    this.resetList();
    this.isLoadingList = true;
    this.loaderService.show();
    this.surveysApiService.list().subscribe((items) => {
      this.loaderService.dismiss();
      if (items && items.length) {
        items.map(e => {
          this.list.push(new SurveyModel(e));
        });
      }
      this.isLoadingList = false;
      this.loaderService.dismiss();
    }, () => {
      this.isLoadingList = false;
      this.loaderService.dismiss();
    });
  }

  /**
   * Send response
   */
  sendResponse() {
    const currentSurvey = this.activeSurveyDetails;
    const data: any = currentSurvey.toJSON();
    // build the PUT format
    // "answer": {
    //             "value": "true",
    //             "optionId": 14
    //           }
    for (const question of data.questions) {
      for (const option of question.options) {
        // case false
        if (option.answer === null) {
          option.answer = false;
        }
        option.answer = {
          value: String(option.answer),
          optionId: option.id
        };
      }
    }
    data.startDate = currentSurvey.startDate;
    data.endDate = currentSurvey.endDate;
    data.name = currentSurvey.name;

    this.isSavingData = true;
    return new Promise((resolve, reject) => {
      this.isLoadingDetails = true;
      this.surveysApiService.answer(data).subscribe((details) => {
        this.isSavingData = false;
        resolve(true);
      }, err => {
        this.isSavingData = false;
        reject(true);
      });
    });
  }

  /**
   * Get details
   */
  public fetchDetails(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.isLoadingDetails = true;
      this.surveysApiService.read(id).subscribe((details) => {
        this.activeSurveyDetails = new SurveyModel(details);
        this.isLoadingDetails = false;
        resolve(true);
      }, () => {
        this.isLoadingDetails = false;
        resolve(true);
      });
    });
  }

  /**
   * Set current active survey to null
   */
  resetCurrentActiveSurvey() {
    this.activeSurveyDetails = null;
  }
}
