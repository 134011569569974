import { swapPlanningEnum } from './enums/planning.enum';
import { ContractualPeriod } from './models/contractualPeriod';
import { UserService } from './../../shared/services/user.service';
import { API_URLS } from 'src/environments/api-urls';
import { Permission } from './models/swap-request';
import { Planning } from './../../shared/models/planning/planning';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { SwapRequestHistory } from 'src/app/features/swap/models/swapHistory';
import { SwapPlanning } from 'src/app/features/swap/models/swapPlanning';
import { SwapDetails } from './models/swap-details';

@Injectable()
export class SwapService {

  contractualPeriod: string;

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
    moment.locale('fr');
    this.userService.typologySetting.subscribe(typo => {
      this.contractualPeriod = typo.swapRequestSettings.contractualPeriod;
    });
  }

  getPlanning(
    calendar: swapPlanningEnum = swapPlanningEnum.SERVICE,
    startDate: moment.Moment,
    endDate: moment.Moment,
    restDate: moment.Moment = null
    ): Observable<Planning[]> {

    return this.http.get<SwapPlanning[]>(
      `${API_URLS.SWAP}/schedule/${calendar}` +
      `?from=${startDate.format('YYYY-MM-01')}&to=${endDate.format('YYYY-MM-DD')}` +
      `${restDate ? '&restDate=' + restDate.format('YYYY-MM-DD') : ''}`
    )
      .pipe(
        map(days => {
          return days.reduce((result, currentDay) => {
            const indexPlanning = result.findIndex(rs => {
              return moment(rs.month).get('M') === moment(currentDay.date).get('M') &&
                moment(rs.month).get('year') === moment(currentDay.date).get('year');
            });
            const day = new Date(currentDay.date as any);
            const event = {
              start: day,
              title: '',
              meta: {
                shiftId: currentDay.id,
                shifts: currentDay.numberOfPoints > 0 ? Array((currentDay.numberOfPoints)).fill(0) : [],
                numberOfPoints: currentDay.numberOfPoints,
                code: currentDay.code,
                period: currentDay.period,
                valid: currentDay.valid,
                serviceSwapStatus: currentDay.serviceSwapStatus,
                departure: currentDay.departure,
                arrival: currentDay.arrival,
                type: currentDay.type,
                workingTime: currentDay.workingTime,
                reference: currentDay.reference,
                recomposedShift: currentDay.recomposedShift
              }
            };
            if (indexPlanning === -1) {
              const planning = new Planning();
              planning.month = new Date(day.getFullYear(), day.getMonth(), 1);
              planning.events = [];
              planning.events.push(event);
              result.push(planning);
            } else {
              result[indexPlanning].events.push(event);
            }
            return result;
          }, []);
        }),
        map((planning: Planning[]) => {
          const diffMonth = endDate.diff(startDate, 'months') + 1;
          while (planning.length < diffMonth) {
            const pl = new Planning();
            pl.month = startDate.add(planning.length, 'month').toDate();
            pl.events = [];
            planning.push(pl);
          }
          return planning;
        })
      );
  }

  getSwapRequests(): Observable<SwapRequestHistory[]> {
    return this.http.get<SwapRequestHistory[]>(`${API_URLS.SWAP}/history`);
  }
  getSwapPermission(): Observable<Permission> {
    return this.http.get<Permission>(`${API_URLS.SWAP}/check-quota-access`);
  }

  getContractualPeriod(): Observable<ContractualPeriod> {
    return this.http.get<ContractualPeriod>
    (`${API_URLS.CONTRACTUAL_PERIOD}/${this.contractualPeriod}`);
  }

  getSwapDetails(swapId: string): Observable<SwapDetails> {
    return this.http.get<SwapDetails>(`${API_URLS.SWAP_DETAILS}/${swapId}`);
  }

  deleteSwap(swapId: string) {
    return this.http.delete<void>(`${API_URLS.SWAP}/cancel/${swapId}`);
  }
  refuseSwap(swapDetails: SwapDetails) {
    return this.http.put<SwapDetails>(`${API_URLS.SWAP}/refuse`, swapDetails);
  }
  acceptSwap(swapDetails: SwapDetails) {
    return this.http.put<SwapDetails>(`${API_URLS.SWAP}/accept`, swapDetails);
  }
}
