import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { AbsenceService } from '../../absence.service';
import { FullDayAbsenceRequestService } from '../fullDay-absence-request.service';
import * as moment from 'moment';

@Component({
  selector: 'app-full-day-absence-recap',
  templateUrl: './fullDay-absence-recap.component.html',
  styleUrls: ['./fullDay-absence-recap.component.scss']
})
export class FullDayAbsenceRecapComponent implements OnInit {

  absenceData: any = [];
  numberOfDays: number;

  constructor(
    private router: Router,
    public snackbarService: SnackbarService,
    public fullDayAbsenceRequestService: FullDayAbsenceRequestService,
    public absenceService: AbsenceService) { }

  ngOnInit() {
    this.absenceData = this.fullDayAbsenceRequestService.absence;
    this.getNumberOfDays();
  }

  getNumberOfDays() {
   this.numberOfDays = Math.abs(moment(this.absenceData.startDate).diff(moment(this.absenceData.endDate), 'days')) + 1;
  }

  sendDataToHistory() {
    this.absenceService.saveAbsence(this.absenceData, 'FULL_DAY').subscribe(
      () => {
        this.endRequest();
        this.snackbarService.show('ABSENCE_REQUEST.SUCCESS', 'success');
      },
      () => {
        this.snackbarService.show('ABSENCE_REQUEST.ERROR', 'error');
      }
    );
  }

  goToPreviousStep() {
    this.fullDayAbsenceRequestService.step = 2;
  }

  endRequest() {
    this.router.navigate(['/absence/history']);
    this.fullDayAbsenceRequestService.step = 1;
  }

}
