import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountPasswordService } from '../../shared/services/account-password.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { NotificationModalComponent } from 'src/app/shared/components/notification-modal/notification-modal.component';
import { NotificationChoiceSettings } from 'src/app/shared/models/settings/notificationChoiceSettings/notificationChoiceSettings';
import { NotificationChoice } from 'src/app/shared/models/notification-choice/notification-choice';
import { phoneNumberValidator } from 'src/app/shared/utils/validators.phone.number';
import { AsYouType, CountryCode } from 'libphonenumber-js';
import { validateInput } from 'src/app/shared/utils/phone-number-input';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  userId: string;
  userBase: string;
  changePasswordForm: UntypedFormGroup;
  formSubmitted: boolean;
  errorOldPswd: boolean;
  errorNewPswd: boolean;
  displayChangePasswordForm: boolean;
  notificationChoiceSettings: NotificationChoiceSettings;
  notificationChoiceForm: UntypedFormGroup;
  notifiationsChoice: NotificationChoice;
  submitted = false;
  validatePhoneNumberInput = validateInput;
  countryCode: CountryCode;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private accountPasswordService: AccountPasswordService,
    public userService: UserService,
    private modalService: NgbModal,
    private snackbarService: SnackbarService
  ) { }

  get newPswd() { return this.changePasswordForm.get('newPswd'); }
  get newPswdConfirm() { return this.changePasswordForm.get('newPswdConfirm'); }

  ngOnInit() {
    this.countryCode =  localStorage.getItem('country').toLocaleUpperCase() as CountryCode;
    this.setDisplayChangePasswordForm();
    this.createChangePasswordForm();
    this.createNotificationChoiceForm();
    this.notification();
    this.userService.typologySetting.subscribe(typoSettings => {
      this.notificationChoiceSettings = typoSettings.notificationChoiceSettings;
    });
  }

  notification(){
    this.userService.getUserNotificationChoice().subscribe(res => {
      this.notifiationsChoice = res;
      this.setValueForm(this.notifiationsChoice);
    });
  }

  setDisplayChangePasswordForm(): void {
    this.displayChangePasswordForm = !localStorage.getItem('badgeToken');
  }

  createChangePasswordForm() {
    this.changePasswordForm = this.formBuilder.group({
      newPswd: [null, [
        Validators.required,
        Validators.minLength(8),
        this.accountPasswordService.passwordValidator(),
      ]],
      newPswdConfirm: [null, [
        Validators.required,
        Validators.minLength(8)
      ]],
    }, { validator : this.accountPasswordService.mustMatch('newPswd', 'newPswdConfirm')});
  }

  createNotificationChoiceForm() {
    this.notificationChoiceForm = this.formBuilder.group({
      mail: [null, [Validators.maxLength(200), Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      sms: [null, [phoneNumberValidator(this.countryCode)]],
      voice: [null, [phoneNumberValidator(this.countryCode)]],
    });
  }

  setValueForm(dataSettings: NotificationChoice){
    if (dataSettings) {
      const as = new AsYouType('FR');
      as.input(dataSettings.voice);
      this.notificationChoiceForm.controls.mail.setValue(dataSettings.email);
      this.formatPhoneNumber('sms', dataSettings.sms);
      this.formatPhoneNumber('voice', dataSettings.voice);
    }
  }

  onSubmit() {
    this.formSubmitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }
    this.modifyPassword();
  }

  resetForm() {
    this.changePasswordForm.reset();
    this.formSubmitted = false;
    this.resetErrors();
  }

  resetErrors() {
    this.errorOldPswd = false;
    this.errorNewPswd = false;
  }

  modifyPassword() {
    this.accountPasswordService.modifyPassword(
      this.userService.accessToken,
      this.newPswd.value
    ).subscribe(
      (modifyResult) => {

        if (modifyResult.success) {
          const modalRef =  this.modalService.open(NotificationModalComponent, {
            centered: true,
          });
          modalRef.componentInstance.icon = 'icon-checked-green';
          modalRef.componentInstance.title = 'ACCOUNT.MODAL.PASSWORD_MODIFIED.TITLE';
          modalRef.componentInstance.message = 'ACCOUNT.MODAL.PASSWORD_MODIFIED.TEXT';
          this.resetForm();
        } else {
          this.resetErrors();
          if (modifyResult.code === 1) {
            // *ERROR OLD PASSWORD
            this.errorOldPswd = true;
            this.snackbarService.show('ACCOUNT.CHANGE_PASSWORD.ERROR_OLD_PASSWORD', 'error');
          } else if (modifyResult.code === 2) {
            // *ERROR NEW PASSWORD
            this.errorNewPswd = true;
            this.snackbarService.show('ACCOUNT.CHANGE_PASSWORD.ERROR_NEW_PASSWORD', 'error');
          } else {
            // *ERROR OTHER
            this.snackbarService.show('COMMON.NOTIFICATION.GENERAL_ERROR', 'error');
          }
        }

      },
      () => {
        this.snackbarService.show('COMMON.NOTIFICATION.GENERAL_ERROR', 'error');
      }
    );
  }

  get form(): { [key: string]: AbstractControl } { return this.notificationChoiceForm.controls; }

  formatPhoneNumber(control: string, value) {
    const asYouType = new AsYouType(this.countryCode);
    asYouType.input(value);
    this.notificationChoiceForm.controls[control].setValue(asYouType.getNumber().formatInternational());
  }

  saveNotifactions(){
    this.notifiationsChoice.email = this.notificationChoiceForm.controls.mail.value;
    this.notifiationsChoice.sms = this.notificationChoiceForm.controls.sms.value;
    this.notifiationsChoice.voice = this.notificationChoiceForm.controls.voice.value;
    this.userService.saveUserNotificationCjoice(this.notifiationsChoice).subscribe(() => {
      this.snackbarService.show('ACCOUNT.NOTIFICATION.SUCCESS', 'success');
      },
      () => {
        this.snackbarService.show('ACCOUNT.NOTIFICATION.ERROR', 'error');
      });
  }
}
