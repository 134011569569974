import { SwapSearchShift, SwapShift } from './models/swapSearchShift';
import { API_URLS } from 'src/environments/api-urls';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { SwapSearch } from 'src/app/features/swap/models/swapSearch';
import { map } from 'rxjs/operators';
import { IControlReponse } from './interfaces/swapSearchControl';
import { SwapRequestSummary } from './models/swapSummary';
import { SwapDetailsEmployee } from './models/swap-details';
import { SwapTypeEnum } from './enums/requestType.enum';
import { UserService } from 'src/app/shared/services/user.service';
@Injectable()
export class SwapStepsService {

  step = 1;
  continueStep = true;
  selectedDate: moment.Moment;
  shiftId: number;
  swapSearch: SwapSearch = new SwapSearch();
  comment: string;
  userShift: SwapDetailsEmployee;
  swapSummary: SwapRequestSummary;
  message: string;
  title: string;

  constructor(
    private router: Router,
    private http: HttpClient,
    private userService: UserService
  ) { }

  goToPreviousStep() {
    this.step--;
    if (!this.step) {
      this.step = 1;
      this.router.navigate(['swap/new']);
    }
    if (this.step === 2) {
      this.continueEnable(true);
    }
  }

  goToNextStep() {
    if (this.continueStep) {
      this.step++;
    }
  }

  searchControl(): Observable<IControlReponse> {
    const matriculeColleague = this.swapSearch.colleagueShift.adminNumber;
    return this.http.get<IControlReponse>(`${API_URLS.SWAP}/check-counter-balance/${matriculeColleague}`);
  }

  searchSimulationColleague(): Observable<boolean> {
    const adminNumber = this.swapSearch.colleagueShift.adminNumber; // adminNumber of colleague
    const date = this.selectedDate.format('YYYY-MM-DD'); // working date
    const shiftId = this.shiftId.toString(); //  shiftId of current admin
    return this.http.get<boolean>( `${API_URLS.SWAP}/simulation?date=${date}&adminNumber=${adminNumber}&shiftId=${shiftId}`);
  }

  searchSimulationUser(): Observable<boolean> {
    const adminNumber = this.userService.adminNumber; // adminNumber of user
    const date = this.selectedDate.format('YYYY-MM-DD'); // working date
    const shiftId = this.swapSearch.colleagueShift.shift.id.toString(); // shiftId of colleague
    return this.http.get<boolean>( `${API_URLS.SWAP}/simulation?date=${date}&adminNumber=${adminNumber}&shiftId=${shiftId}`);
  }

  searchSimulation(): Observable<boolean> {
    return forkJoin([
      this.searchSimulationColleague(),
      this.searchSimulationUser()
    ]).pipe(
      map(results => {
        return results[0] && results[1];
      })
    );
  }

  reset() {
    this.step = 1;
    this.selectedDate = null;
    this.resetSwapSearch();
    this.comment = null;
  }

  continueEnable(enabled: boolean) {
    this.continueStep = enabled;
  }

  selectDate(date: moment.Moment) {
    this.selectedDate = date;
  }

  resetSwapSearch() {
    this.swapSearch = new SwapSearch();
  }

  setShiftId(id: number) {
    this.shiftId = id;
  }

  getSearchShift(shiftDate: moment.Moment, startTime: number, endTime: number, searchTerm: string): Observable<SwapSearchShift[]> {
    return this.http.post<SwapSearchShift[]>(`${API_URLS.SWAP}/search/${shiftDate.format('YYYY-MM-DD')}`, {
      startTime,
      endTime,
      searchTerm
    });
  }

  setUserShift(meta: any) {
    const userAdminNumber = this.userService.adminNumber;
    const shift = new SwapShift(
      meta.shiftId,
      this.selectedDate.toDate(),
      meta.type,
      meta.departure,
      meta.arrival,
      meta.workingTime,
      meta.reference // *sender label
    );
    this.userShift = new SwapDetailsEmployee({
      adminNumber: userAdminNumber,
      firstName: null,
      lastName: null,
      shift
    });
  }

  setSwapSummary() {
    this.swapSummary = new SwapRequestSummary(
      this.userShift,
      new SwapDetailsEmployee({...this.swapSearch.colleagueShift}),
      this.selectedDate ? this.selectedDate.format('YYYY-MM-DD') : null,
      this.selectedDate ? this.selectedDate.format('YYYY-MM-DD') : null,
      SwapTypeEnum.SERVICE,
      this.comment,
      this.message,
      this.title
    );
  }

  sendSwapRequest(): Observable<void> {
    this.setSwapSummary();
    return this.http.post<void>(`${API_URLS.SWAP}/create`, this.swapSummary);
  }
}
