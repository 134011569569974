import { NotificationModalComponent } from './components/notification-modal/notification-modal.component';
import { PlanningDetailsComponent } from './components/planning-details/planning-details.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HeaderComponent } from './components/header/header.component';
import { ScheduleSwiperComponent } from './components/schedule-swiper/schedule-swiper.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SideBarService } from './components/sidebar/sidebar.service';
import { HourFormatPipe } from './pipes/hourFormatPipe/hourFormat.pipe';
import { DateFormatPipe } from './pipes/dateFormat/dateFormat.pipe';
import { TabsComponent } from './components/tabs/tabs.component';
import { LinkyModule } from 'ngx-linky';
import { ReactiveFormsModule } from '@angular/forms';
import { CalenderCardComponent } from './components/calender-card/calender-card.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { HoursMinutesConverterPipe } from './pipes/hoursMinutesConverter/hoursMinutesConverter.pipe';
import { MessagesCountService } from './services/messages-count.service';
import { LoaderModalComponent } from './components/loader-modal/loader-modal.component';
import { AnalyticsService } from './services/GTM/analytics.service';
import { DataLayerCustomDimensionsService } from './services/GTM/dataLayerCustomDimensions.service';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { HourHundredthsConverterPipe } from './pipes/hourHundredthsConverter/hourHundredthsConverter.pipe';
import { IconArrowComponent } from './components/icon-arrow/icon-arrow.component';
import { EllipsisPipe } from './pipes/ellipsis/ellipsis.pipe';
import { AccountPasswordService } from './services/account-password.service';
import { LoaderComponent } from './components/loader/loader.component';
import {MatTableModule} from '@angular/material/table';
import { AngularMaterialModule } from './angular-material.module';
import { FileSizePipe } from './pipes/file-size/file-size.pipe';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorI18nService } from './services/internationalization/mat-paginator-i18n.service';
import { JoinWordsPipe } from './pipes/join-words/join-words.pipe';

@NgModule({
    declarations: [
        MainLayoutComponent,
        SidebarComponent,
        HeaderComponent,
        HourFormatPipe,
        DateFormatPipe,
        HoursMinutesConverterPipe,
        HourHundredthsConverterPipe,
        ScheduleSwiperComponent,
        SnackbarComponent,
        TabsComponent,
        CalenderCardComponent,
        PlanningDetailsComponent,
        NotificationModalComponent,
        ConfirmationModalComponent,
        LoaderModalComponent,
        IconArrowComponent,
        LoaderComponent,
        EllipsisPipe,
        FileSizePipe,
        JoinWordsPipe
    ],
    imports: [
        CommonModule,
        NgbModule,
        RouterModule,
        TranslateModule,
        DragDropModule,
        LinkyModule,
        ReactiveFormsModule,
        AngularMaterialModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        })
    ],
    exports: [
        NgbModule,
        MainLayoutComponent,
        SidebarComponent,
        HeaderComponent,
        HourFormatPipe,
        DateFormatPipe,
        HoursMinutesConverterPipe,
        HourHundredthsConverterPipe,
        ScheduleSwiperComponent,
        SnackbarComponent,
        TabsComponent,
        ReactiveFormsModule,
        CalenderCardComponent,
        IconArrowComponent,
        PlanningDetailsComponent,
        NotificationModalComponent,
        ConfirmationModalComponent,
        LoaderComponent,
        EllipsisPipe,
        MatTableModule,
        FileSizePipe,
        JoinWordsPipe,
        AngularMaterialModule
    ],
    providers: [
        SideBarService,
        MessagesCountService,
        NgbActiveModal,
        AnalyticsService,
        DataLayerCustomDimensionsService,
        AccountPasswordService,
        {
            provide: MatPaginatorIntl,
            useClass: MatPaginatorI18nService,
        },
    ]
})
export class SharedModule {

}
