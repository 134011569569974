import { StateEnum } from './../enums/swapHistoryState.enum';
import { TranslateService } from '@ngx-translate/core';
import { OnInit, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SwapService } from '../swap.service';
import { SwapDetails } from '../models/swap-details';
import { SwapTypeEnum } from '../enums/requestType.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { ConfirmationModalComponent } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';
import { UserService } from 'src/app/shared/services/user.service';


@Component({
  selector: 'app-swap-details',
  templateUrl: './swap-details.component.html',
  styleUrls: ['./swap-details.component.scss']
})
export class SwapDetailsComponent implements OnInit {


  swapDetails: SwapDetails;
  paramId: string;
  swapTypeEnum = SwapTypeEnum;
  swapStateEnum = StateEnum;

  constructor(
    public route: ActivatedRoute,
    public modalService: NgbModal,
    public userService: UserService,
    private translateService: TranslateService,
    private swapService: SwapService,
    private snackBarService: SnackbarService,
    private router: Router
  ) { }

  ngOnInit() {
    this.paramId = this.route.snapshot.paramMap.get('id');
    this.getSwapDetails(this.paramId);
  }


  getSwapDetails(swapId: string) {
    return this.swapService.getSwapDetails(swapId).subscribe((details: SwapDetails) => {
      this.swapDetails = new SwapDetails({ ...details });
    });
  }


  refuse() {
    this.swapDetails.title = this.translateService.instant('SWAP_REQUEST.DETAILS.ACCEPT_REFUSE_SWAP_REQUEST');
    this.swapDetails.message = this.translateService.instant(
      'SWAP_REQUEST.DETAILS.DESCRIPTION.REFUSED_COLLEAGUE',
      { firstName: this.swapDetails.receiver.firstName, lastName: this.swapDetails.receiver.lastName }
    );
    this.swapService.refuseSwap(this.swapDetails).subscribe(() => {
      this.getSwapDetails(this.paramId);
    });
  }

  accept() {
    this.swapDetails.title = this.translateService.instant('SWAP_REQUEST.DETAILS.ACCEPT_REFUSE_SWAP_REQUEST');
    this.swapDetails.message = this.translateService.instant(
      'SWAP_REQUEST.DETAILS.DESCRIPTION.PENDING_OPERATIONS',
      { firstName: this.swapDetails.receiver.firstName, lastName: this.swapDetails.receiver.lastName }
    );
    this.swapService.acceptSwap(this.swapDetails).subscribe(() => {
      this.getSwapDetails(this.paramId);
    });
  }

  cancel() {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      centered: true
    });
    modalRef.componentInstance.title = 'EXCHANGE.CANCEL_ASK_MODAL.TITLE';
    modalRef.componentInstance.message = 'EXCHANGE.CANCEL_ASK_MODAL.MESSAGE';
    modalRef.componentInstance.icon = 'icon-alert-red';
    modalRef.result.then(() => {
      this.swapService.deleteSwap(this.paramId).subscribe(() => {
        this.snackBarService.show('EXCHANGE.CANCEL_ASK.MESSAGE.CANCEL_SUCCESS', 'success');
        this.router.navigate(['/swap/history']);
      });
    }, () => { });
  }
  getBack(){
    this.router.navigate(['swap/history']);
  }

}
