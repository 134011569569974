import {BaseModel} from '@shared/models/BaseModel';
import {SurveyQuestionModel} from '@shared/models/surveys/surveyQuestion.model';
import SurveyStatusEnum from '@shared/enums/surveys/surveyStatus.enum';

export class SurveyModel extends BaseModel {
  /**
   * Id
   *
   */
  id: number;
  /**
   *
   *
   */
  name: string;
  /**
   *
   */
  ref: string;
  /**
   *
   */
  description: string;
  /**
   * YYYY-MM-DD
   */
  endDate: string;
  /**
   * YYYY-MM-DD
   */
  startDate: string;
  /**
   *
   */
  status: SurveyStatusEnum;
  /**
   *
   */
  anonymous: boolean;
  /**
   *
   */
  messageBeforeOpening: boolean;
  /**
   *
   */
  messageBeforeClosing: boolean;
  /**
   *
   */
  zoneId: string;

  /**
   *
   */
  typologies: object;
  /**
   *
   */
  participation: boolean;
  /**
   * Question list item
   */
  questions: Array<SurveyQuestionModel>;

  constructor(args: {
    id: number,
    state: string,
    name: string,
    questions: string,
    anonymous: boolean,
    messageBeforeClosing: string,
    messageBeforeOpening: string,
    typologies: string,
    endData: string,
    startDate: string,
    zoneId: string
  }) {
    super(args);
    this._buildQuestions();
  }

  /**
   *
   */
  private _buildQuestions() {
    // get a copy of questions as JSON object to transform them to models
    const questions = JSON.parse(JSON.stringify(this.questions));
    if (questions) {
      // sort based on rank
      questions.sort((a, b) => {
        return a.rank > b.rank ? 1 : -1;
      });
      this.questions = [];
      for (const item of questions) {
        this.questions.push(new SurveyQuestionModel(item));
      }
    } else {
      this.questions = [];
    }
  }

  /**
   * Minimal serialisation
   */
  toJSON(fields = []) {
    return {
      id: this.id,
      questions: this.questions.map(e => e.toJSON())
    };
  }
}

