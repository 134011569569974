import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class ParamsAccessService implements CanActivate {

  private redirect = '/home';

  constructor(
    private router: Router,
  ) { }

  canActivate(): boolean {
    this._putUrlParamsInLocalStorage();
    this.router.navigate([this.redirect]);
    return true;
  }

  private _putUrlParamsInLocalStorage(): void {
    // remove high-ASCII characters
    const url = decodeURIComponent(window.location.href).replace(/[^\x00-\x7F]/g, '');

    let paramValue: string;
    if (url.includes('?')) {
      const httpParams = new HttpParams({fromString: url.split('?')[1]});
      for (const paramKey of httpParams.keys()) {
        paramValue = httpParams.get(paramKey);
        if (paramKey === 'redirect') {
          this.redirect = paramValue;
          continue;
        }
        localStorage.setItem(paramKey, paramValue);
      }
    }
  }
}
