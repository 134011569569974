import { Component, OnInit } from '@angular/core';
import { AbsenceRequestsHistory } from 'src/app/shared/models/absence/absenceHistory';
import { LoaderModalService } from 'src/app/shared/services/loader-modal.service';
import { AbsenceService } from '../absence.service';

@Component({
  selector: 'app-absence-history',
  templateUrl: './absence-history.component.html',
  styleUrls: ['./absence-history.component.scss']
})
export class AbsenceHistoryComponent implements OnInit {

  absenceRequestsHistory: AbsenceRequestsHistory;

  requestsParam = {count: 0};
  errorGetRequests: boolean;
  loadingRequests = true;

  constructor(private absenceService: AbsenceService, private loaderModalService: LoaderModalService) { }

  ngOnInit() {
    this.getAbsenceHistory();
  }

  getAbsenceHistory() {
    this.loaderModalService.show();
    this.absenceService.getAbsenceRequests().subscribe(data => {
      this.absenceRequestsHistory = data;
      this.requestsParam.count = this.absenceRequestsHistory.absenceRequests.length;
      this.loaderModalService.dismiss();
      this.loadingRequests = false;
    },
    err => {
      this.loaderModalService.dismiss();
      this.errorGetRequests = true;
      this.loadingRequests = false;
    });
  }
}
