import { CounterBalanceModalComponent } from '../../counter-balance-modal/counter-balance-modal.component';
import { AbsenceService } from './../../absence.service';
import { PartialAbsenceRequestService } from '../partial-absence-request.service';
import { Component, OnInit } from '@angular/core';
import { Planning } from 'src/app/shared/models/planning/planning';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoaderModalService } from 'src/app/shared/services/loader-modal.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ScheduleSettings } from 'src/app/shared/models/settings/settings';
import { UserService } from 'src/app/shared/services/user.service';


@Component({
  selector: 'app-partial-absence-select',
  templateUrl: './partial-absence-select.component.html',
  styleUrls: ['./partial-absence-select.component.scss']
})
export class PartialAbsenceSelectComponent implements OnInit {

  planning: Planning[] = [];
  isScrolledDown = false;
  daySelected: Planning;
  sub: Subscription;
  scheduleSettings: ScheduleSettings;

  constructor(
    public partialAbsenceRequestService: PartialAbsenceRequestService,
    public loaderModalService: LoaderModalService,
    public absenceService: AbsenceService,
    public modalService: NgbModal,
    private userService: UserService
    ) {}

  ngOnInit() {
    const startDate = moment().subtract(1, 'months');
    const endDate = moment().add(4, 'months').endOf('month');
    this.getAbsenceCalendar(startDate, endDate, 'DOWN');
    this.getScheduleSettings();
  }

  getAbsenceCalendar(startDate: moment.Moment, endDate: moment.Moment, scroll = 'DOWN') {
    this.loaderModalService.show();
    this.absenceService.getPlanning(startDate, endDate, 'PARTIAL').subscribe(planning => {
      if (scroll === 'DOWN') {
      this.planning = this.planning.concat(planning);
      }
      this.loaderModalService.dismiss();
    },
    () => {
      this.loaderModalService.dismiss();
    });
  }

  getScheduleSettings(){
    this.sub = this.userService.typologySetting.subscribe(
      settings => {
        this.scheduleSettings = settings.scheduleSettings;
      }
    );
  }

  onScrollDown() {
    const startDate = moment().add(5, 'months');
    const endDate = moment().add(10, 'months').endOf('month');
    this.getAbsenceCalendar(startDate, endDate, 'DOWN');
  }

  onScroll() {
    const planningContainerHeight = document.getElementById('partialAbsenceCalendar');
    if (planningContainerHeight.scrollTop === (planningContainerHeight.scrollHeight - planningContainerHeight.offsetHeight)
     && !this.isScrolledDown) {
      this.isScrolledDown = true;
      this.onScrollDown();
    }
  }

  selectDay(day: Planning) {
    if (this.daySelected === day) {
      this.daySelected = null;
      this.partialAbsenceRequestService.selectDate(null);
      this.partialAbsenceRequestService.continueEnable(false);
    } else {
      this.daySelected = day;
      this.partialAbsenceRequestService.selectDate(moment(day.date));
      this.partialAbsenceRequestService.continueEnable(true);
    }
  }

  openCounterBalancePopin() {
    const counterBalanceModalRef = this.modalService.open(CounterBalanceModalComponent, {
      centered: true,
      size: 'lg'
    });
    counterBalanceModalRef.componentInstance.date = moment();
    counterBalanceModalRef.componentInstance.absenceType = 'PARTIAL';
  }

  goToNextStep() {
    if (this.daySelected) {
      const date = moment(this.daySelected.date);
      this.partialAbsenceRequestService.absenceData.startDate = date;
      this.partialAbsenceRequestService.goToNextStep();
    }
  }

  goToPreviousStep() {
    this.partialAbsenceRequestService.goToPrevStep();
  }
}
